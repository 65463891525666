import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Button,
  Row,
  Col,
  InputGroup,
  Spinner
} from "react-bootstrap";
import { Country, State } from "country-state-city";
import "react-toastify/dist/ReactToastify.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faCity, faEnvelope, faGlobe, faMap } from '@fortawesome/free-solid-svg-icons';
import { toast } from "react-toastify";

const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;

const AddCompanyModal = (props) => {
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [fax, setFax] = useState("");
  const [companyLogo, setCompanyLogo] = useState(null);
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [file, setFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [countries, setAllCountries] = useState([]);
  const [states, setAllStates] = useState([]);

  const handleCountryChange = (event) => {
    const newCountry = event.target.value;
    setCountry(newCountry);
  };

  const handleStateChange = (event) => {
    const newState = event.target.value;
    setState(newState);
  };

  useEffect(() => {
    const countries = Country.getAllCountries();
    setAllCountries(countries);
    if (!props.restaurant) {
      const malaysia = countries.find((country) => country.name === "Malaysia");
      setCountry(malaysia.isoCode);
    }
  }, []);

  useEffect(() => {
    if (country) {
      const statesForCountry = State.getStatesOfCountry(country);
      setAllStates(statesForCountry);
      if (!props.restaurant) {
        setState("");
      }
    }
  }, [country]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("name", name);
    formData.append("contact", contact);
    formData.append("email", email);
    formData.append("fax", fax);
    formData.append("address_line_1", addressLine1);
    formData.append("address_line_2", addressLine2);
    formData.append("zip_code", zipCode);
    formData.append("city", city);
    formData.append("state", state);
    formData.append("country", country);
    formData.append("file", file);
    formData.append("companyLogo", companyLogo);

    let isTimedOut = false;

    const timeout = setTimeout(() => {
      isTimedOut = true;
      setIsSubmitting(false);
      toast.error("Request timed out. Please try again later.", {
        containerId: "error-container",
      });
      props.handleCloseForm();
    }, 5000);

    fetch(`${backendUri}/api/v0/admin/companies`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${props.Token}`,
      },
      body: formData
    })
      .then((res) => {
        clearTimeout(timeout);
        return res.json();
      })
      .then((res) => {
        console.log(res.error);
        if (res.error) {
          toast.error(res.message, {
            containerId: "error-container",
          });
          props.handleCloseForm();
        } else {
          toast.success(res.message, {
            containerId: "success-container",
          });

          setName("");
          setContact("");
          setEmail("");
          setFax("");
          setCompanyLogo(null);
          setAddressLine1("");
          setAddressLine2("");
          setZipCode("");
          setState("");
          setCity("");
          setCountry("");
          setFile(null);

          props.fetchData(props.Token);
          props.handleCloseForm();
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Modal show={props.showForm} onHide={props.handleCloseForm} size="lg">
        <Modal.Header closeButton>
          <Modal.Title> Create New Company </Modal.Title>
        </Modal.Header>
        <Form style={{ margin:3}} onSubmit={(e) => handleSubmit(e)}>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group controlId="addCompanyName">
                <Form.Label> Company Name* </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="fa fa-tag" />
                  </InputGroup.Text>
                  <Form.Control 
                    required
                    type="text"
                    placeholder="Enter Company Name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group controlId="addCompanyContact">
                <Form.Label> Contact* </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="fa fa-phone" />
                  </InputGroup.Text>
                  <Form.Control 
                    required
                    type="text"
                    placeholder="Enter Contact"
                    value={contact}
                    onChange={(e) => {
                      setContact(e.target.value);
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group controlId="addCompanyEmail">
                <Form.Label> Email* </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="fa fa-inbox" />
                  </InputGroup.Text>
                  <Form.Control 
                    required
                    type="text"
                    placeholder="Enter Email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group controlId="addCompanyFax">
                <Form.Label> Fax </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="fa fa-fax" />
                  </InputGroup.Text>
                  <Form.Control 
                    type="text"
                    placeholder="Enter Fax"
                    value={fax}
                    onChange={(e) => {
                      setFax(e.target.value);
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group controlId="addCompanyAddressLine1">
                <Form.Label> Address Line 1* </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                  </InputGroup.Text>
                  <Form.Control 
                    required
                    type="text"
                    placeholder="Enter Address Line 1"
                    value={addressLine1}
                    onChange={(e) => {
                      setAddressLine1(e.target.value);
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group controlId="addCompanyAddressLine2">
                <Form.Label> Address Line 2* </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                  </InputGroup.Text>
                  <Form.Control 
                    required
                    type="text"
                    placeholder="Enter Address Line 2"
                    value={addressLine2}
                    onChange={(e) => {
                      setAddressLine2(e.target.value);
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="addCompanyCity">
                <Form.Label> City* </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faCity} />
                  </InputGroup.Text>
                  <Form.Control 
                    required
                    type="text"
                    placeholder="Enter City"
                    value={city}
                    onChange={(e) => {
                      setCity(e.target.value);
                    }}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} controlId="addCompanyZipCode">
                <Form.Label> Zip Code* </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </InputGroup.Text>
                  <Form.Control 
                    required
                    type="number"
                    placeholder="Enter Zip Code"
                    value={zipCode}
                    onChange={(e) => {
                      setZipCode(e.target.value);
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="addCompanyCountry">
                <Form.Label>Country*</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faGlobe} />
                  </InputGroup.Text>
                  <Form.Select
                    name="country"
                    onChange={handleCountryChange}
                    value={country}
                    required
                  >
                    <option value="">Choose...</option>
                    {countries.map((country) => (
                      <option key={country.isoCode} value={country.isoCode}>
                        {country.name}
                      </option>
                    ))}
                  </Form.Select>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} controlId="addCompanyState">
                <Form.Label>State*</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faMap} />
                  </InputGroup.Text>
                  <Form.Select
                    name="state"
                    onChange={handleStateChange}
                    value={state}
                    required
                  >
                    <option value="">Choose...</option>
                    {states.map((state) => (
                      <option key={state.isoCode} value={state.isoCode}>
                        {state.name}
                      </option>
                    ))}
                  </Form.Select>
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group controlId="addCompanyLogo">
                <Form.Label>Company Logo</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="fa fa-image" />
                  </InputGroup.Text>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    name="logo"
                    onChange={(e) => {
                      if (e.target.files.length > 0) {
                        setFile(1);
                        setCompanyLogo(e.target.files[0]);
                      } else {
                        setFile(0);
                      }
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="soft-primary"
              type="submit"
              id="addSubmitBtn"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                    Submitting...
                </>
              ) : (
                "Submit"
              )}
            </Button>
            <Button
              variant="soft-secondary"
              onClick={props.handleCloseForm}
              id="addCloseBtn"
            >
                Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
};

export default AddCompanyModal;