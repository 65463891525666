import React, { useState } from "react";
import SimpleBar from "simplebar-react";
//Images
import avatar2 from "../../assets/dist/img/avatar2.jpg";
import avatar3 from "../../assets/dist/img/avatar3.jpg";
import avatar4 from "../../assets/dist/img/avatar4.jpg";
import avatar10 from "../../assets/dist/img/avatar10.jpg";
import avatar12 from "../../assets/dist/img/avatar12.jpg";
import {
  AlignLeft,
  Bell,
  Calendar,
  CheckSquare,
  Clock,
  CreditCard,
  Inbox,
  Plus,
  Search,
  Settings,
  Tag,
} from "react-feather";
import {
  Button,
  Container,
  Dropdown,
  Form,
  InputGroup,
  Nav,
  Navbar,
} from "react-bootstrap";
import { toggleCollapsedNav } from "../../redux/action/Theme";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import CustomInput from './CustomInput';
import classNames from "classnames";
import { motion } from "framer-motion";
import HkBadge from "../../components/@hk-badge/@hk-badge";
import UserAccount from "./UserAccount";

const TopNav = ({ navCollapsed, toggleCollapsedNav }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const CloseSearchInput = () => {
    setSearchValue("");
    setShowDropdown(false);
  };

  const pageVariants = {
    initial: {
      opacity: 0,
      y: 10,
    },
    open: {
      opacity: 1,
      y: 0,
    },
    close: {
      opacity: 0,
      y: 10,
    },
  };

  return (
    <Navbar expand="xl" className="hk-navbar navbar-light fixed-top" style={{boxShadow: "0 5px 5px #CCC"}}>
      <Container fluid>
        {/* Start Nav */}
        <div className="nav-start-wrap mx-auto">
          <p style={{fontWeight: "bold", color: "blue", fontSize: "25px" }}>WENS WebLink BMS Software</p>
          {/* Search */}
          <Dropdown
            as={Form}
            className="navbar-search"
            show={showDropdown}
            autoClose={() => setShowDropdown(!showDropdown)}
          >
            <Dropdown.Toggle as="div" className="no-caret bg-transparent">
              {/* <Button
                variant="flush-dark"
                className="btn-icon btn-rounded flush-soft-hover  d-xl-none"
                onClick={() => setShowDropdown(!showDropdown)}
              >
                <span className="icon">
                  <span className="feather-icon">
                    <Search />
                  </span>
                </span>
              </Button> */}
             
              {/* <InputGroup className="d-xl-flex d-none">
                <span className="input-affix-wrapper input-search affix-border">
                  <Form.Control
                    type="text"
                    className="bg-transparent"
                    data-navbar-search-close="false"
                    placeholder="Search..."
                    aria-label="Search"
                    onFocus={() => setShowDropdown(true)}
                    onBlur={() => setShowDropdown(false)}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  <span
                    className="input-suffix"
                    onClick={() => setSearchValue("")}
                  >
                    <span>/</span>
                    <span className="btn-input-clear">
                      <i className="bi bi-x-circle-fill" />
                    </span>
                    <span
                      className="spinner-border spinner-border-sm input-loader text-primary"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </span>
                  </span>
                </span>
              </InputGroup> */}
            </Dropdown.Toggle>
       
          </Dropdown>
        </div>
        {/* /Start Nav */}
        {/* End Nav */}
        <div className="nav-end-wrap">
          <Nav className="navbar-nav flex-row">
            {/* <Nav.Item>
              <Button
                variant="flush-dark"
                as={Link}
                to="/apps/email"
                className="btn-icon btn-rounded flush-soft-hover"
              >
                <span className="icon">
                  <span className=" position-relative">
                    <span className="feather-icon">
                      <Inbox />
                    </span>
                    <HkBadge
                      bg="primary"
                      soft
                      pill
                      size="sm"
                      className="position-top-end-overflow-1"
                    >
                      4
                    </HkBadge>
                  </span>
                </span>
              </Button>
            </Nav.Item> */}
            {/* <Nav.Item>
              <Dropdown className="dropdown-notifications">
                <Dropdown.Toggle
                  variant="flush-dark"
                  className="btn-icon btn-rounded flush-soft-hover no-caret"
                >
                  <span className="icon">
                    <span className="position-relative">
                      <span className="feather-icon">
                        <Bell />
                      </span>
                      <HkBadge
                        bg="success"
                        indicator
                        className="position-top-end-overflow-1"
                      />
                    </span>
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu align="end" className="p-0">
                  <Dropdown.Header className="px-4 fs-6">
                    Notifications
                    <Button
                      variant="flush-dark"
                      className="btn-icon btn-rounded flush-soft-hover"
                    >
                      <span className="icon">
                        <span className="feather-icon">
                          <Settings />
                        </span>
                      </span>
                    </Button>
                  </Dropdown.Header>
                  <SimpleBar className="dropdown-body  p-2">
                    <Dropdown.Item>
                      <div className="media">
                        <div className="media-head">
                          <div className="avatar avatar-rounded avatar-sm">
                            <img
                              src={avatar2}
                              alt="user"
                              className="avatar-img"
                            />
                          </div>
                        </div>
                        <div className="media-body">
                          <div>
                            <div className="notifications-text">
                              Morgan Freeman accepted your invitation to join
                              the team
                            </div>
                            <div className="notifications-info">
                              <HkBadge bg="success" soft>
                                Collaboration
                              </HkBadge>
                              <div className="notifications-time">
                                Today, 10:14 PM
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <div className="media">
                        <div className="media-head">
                          <div className="avatar  avatar-icon avatar-sm avatar-success avatar-rounded">
                            <span className="initial-wrap">
                              <span className="feather-icon">
                                <Inbox />{" "}
                              </span>
                            </span>
                          </div>
                        </div>
                        <div className="media-body">
                          <div>
                            <div className="notifications-text">
                              New message received from Alan Rickman
                            </div>
                            <div className="notifications-info">
                              <div className="notifications-time">
                                Today, 7:51 AM
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <div className="media">
                        <div className="media-head">
                          <div className="avatar  avatar-icon avatar-sm avatar-pink avatar-rounded">
                            <span className="initial-wrap">
                              <span className="feather-icon">
                                <Clock />
                              </span>
                            </span>
                          </div>
                        </div>
                        <div className="media-body">
                          <div>
                            <div className="notifications-text">
                              You have a follow up with Jampack Head on Friday,
                              Dec 19 at 9:30 am
                            </div>
                            <div className="notifications-info">
                              <div className="notifications-time">
                                Yesterday, 9:25 PM
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <div className="media">
                        <div className="media-head">
                          <div className="avatar avatar-sm avatar-rounded">
                            <img
                              src={avatar3}
                              alt="user"
                              className="avatar-img"
                            />
                          </div>
                        </div>
                        <div className="media-body">
                          <div>
                            <div className="notifications-text">
                              Application of Sarah Williams is waiting for your
                              approval
                            </div>
                            <div className="notifications-info">
                              <div className="notifications-time">
                                Today 10:14 PM
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <div className="media">
                        <div className="media-head">
                          <div className="avatar avatar-sm avatar-rounded">
                            <img
                              src={avatar10}
                              alt="user"
                              className="avatar-img"
                            />
                          </div>
                        </div>
                        <div className="media-body">
                          <div>
                            <div className="notifications-text">
                              Winston Churchil shared a document with you
                            </div>
                            <div className="notifications-info">
                              <HkBadge bg="violet" soft>
                                File Manager
                              </HkBadge>
                              <div className="notifications-time">
                                2 Oct, 2021
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <div className="media">
                        <div className="media-head">
                          <div className="avatar  avatar-icon avatar-sm avatar-danger avatar-rounded">
                            <span className="initial-wrap">
                              <span className="feather-icon">
                                <Calendar />
                              </span>
                            </span>
                          </div>
                        </div>
                        <div className="media-body">
                          <div>
                            <div className="notifications-text">
                              Last 2 days left for the project to be completed
                            </div>
                            <div className="notifications-info">
                              <HkBadge bg="orange" soft>
                                Updates
                              </HkBadge>
                              <div className="notifications-time">
                                14 Sep, 2021
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Dropdown.Item>
                  </SimpleBar>
                  <div className="dropdown-footer">
                    <Link to="#">
                      <u>View all notifications</u>
                    </Link>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </Nav.Item> */}
            <UserAccount />
          </Nav>
        </div>
        {/* /End Nav */}
      </Container>
    </Navbar>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed } = theme;
  return { navCollapsed };
};

export default connect(mapStateToProps, { toggleCollapsedNav })(TopNav);
