import { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  TabContent,
  Table,
  Form,
  InputGroup,
} from "react-bootstrap";
import { Plus } from "react-feather";
import { ToastContainer } from "react-toastify";
import DeleteModal from "./DeleteModal";
import EditFormModal from "./EditFormModal";
import Cookies from "js-cookie";
import { useAuth } from "../../services/authContext";
import useUserServices from "../../services/userService";
import useCompanyServices from "../../services/companyService";
// eslint-disable-next-line no-undef

const UserManagement = () => {
  // Services
  const {getAllUserAccounts} = useUserServices()
  const {getAllCompanies} = useCompanyServices();
  // Access token variable
  const [Token] = useState(Cookies.get("BMStoken"));
  const { user } = useAuth();

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  // Modal show variable
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  // const [editUser, setEditUser] = useState();

  // Fetch data use
  const [lists, setList] = useState([]);

  // ID get
  const [userId, setUserId] = useState("");

  //Search Variable
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredList, setFilteredLists] = useState([...lists]);

  const [companies, setCompanies] = useState();
  
  const getCompanies = async () => {
    const companies = await getAllCompanies();
    return companies.data
  }

  useEffect(() => {
    fetchData(Token);
  }, [Token]);

  useEffect(() => {
    if (searchTerm) {
      const filterItems = lists.filter(
        (item) =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.role.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredLists(filterItems);
    } else {
      setFilteredLists([...lists]);
    }
  }, [lists, searchTerm]);

  const fetchData = async () => {
    const users = await getAllUserAccounts();

    if(users.success){
      setList(users.data)
      const companies = await getCompanies();
      if(Array.isArray(companies)){
        setCompanies(companies)
      }
    }
  };

  // Create Modal Show
  const handleCreateClick = () => {
    setShowEditForm(true);
    setIsEdit(false);
  };

  // Delete Modal Show
  const handleDeleteClick = (id) => {
    setUserId(id);
    setShowDeleteForm(true);
  };
  const handleCloseDeleteForm = () => {
    setUserId("");
    setShowDeleteForm(false);
  };

  // Update Modal Show
  const handleUpdateClick = (id) => {
    setUserId(id);
    setShowEditForm(true);
    setIsEdit(true)
    // setEditUser(true)
  };
  const handleCloseEditForm = () => {
    setUserId("");
    setShowEditForm(false);
  };

  //Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  //Update the render to use filteredLists
  const currentItems = filteredList.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <Container>
        <TabContent>
          <Card className="card-border mb-0 h-100">
            <Card.Header className="card-header-Action">
              <h5>User Management</h5>
              <Button
                variant="primary btn-animated"
                size="sm"
                className="ms-3"
                id="addUser"
                onClick={handleCreateClick}
              >
                <span>
                  <span className="icon">
                    <span className="feather-icon">
                      <Plus />
                    </span>
                  </span>
                  <span className="btn-text " size="lg">
                    Add new
                  </span>
                </span>
              </Button>
            </Card.Header>
            <Card.Body>
              {/* Search function */}
              <InputGroup className="mb-4">
                <Form.Control
                  placeholder="Search..."
                  className="table-search"
                  type="text"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </InputGroup>
              <div className="invoice-list-view">
                <div className="table-responsive">
                  <Table hover className="mb-0">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Company</th>
                        <th>Created By</th>
                        <th>Updated By</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody id="dataTable">
                      {currentItems.map((item) => (
                        <tr key={item.id}>
                          <td>{item.name}</td>
                          <td>{item.email}</td>
                          <td>{item.role}</td>
                          <td>{item.company}</td>
                          <td>{item.created_by}</td>
                          <td>{item.updated_by}</td>
                          <td>
                            <Button
                              id="editBtn"
                              size="sm"
                              variant="soft-primary"
                              style={{ margin: 5 }}
                              className="btn-rounded"
                              onClick={() => {
                                handleUpdateClick(item.id);
                              }}
                            >
                              Edit
                            </Button>

                            {item.id !== user._id ? (
                              <Button
                                id="deleteBtn"
                                size="sm"
                                variant={"soft-danger"}
                                style={{ margin: 5 }}
                                className="btn-rounded"
                                onClick={() => {
                                  handleDeleteClick(item.id);
                                }}
                              >
                                Delete
                              </Button>
                            ) : null}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Row>
                    <Col
                      md={{ span: 2, offset: 10 }}
                      xs={{ span: 12, offset: 0 }}
                      className="text-center mt-3"
                    >
                      <Button
                        variant="primary btn-animated"
                        className="btn-icon"
                        disabled={currentPage === 1}
                        onClick={() => paginate(currentPage - 1)}
                      >
                        <span>
                          <i className="ri-arrow-left-s-line" />
                        </span>
                      </Button>
                      <span className="mx-1">{currentPage}</span>
                      <Button
                        variant="primary btn-animated "
                        className="btn-icon"
                        disabled={indexOfLastItem >= lists.length}
                        onClick={() => paginate(currentPage + 1)}
                      >
                        <span>
                          <i className="ri-arrow-right-s-line" />
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card.Body>
          </Card>
        </TabContent>
      </Container>
      <ToastContainer
        position="top-right"
        style={{ top: "70px", right: "20px" }}
        enableMultiContainer
        containerId="toast-container"
      />
      <DeleteModal
        showForm={showDeleteForm}
        handleCloseForm={handleCloseDeleteForm}
        Token={Token}
        userId={userId}
        fetchData={fetchData}
      />
      <EditFormModal
        showForm={showEditForm}
        handleCloseForm={handleCloseEditForm}
        Token={Token}
        userId={userId}
        fetchData={fetchData}
        companies = {companies}
        isEdit =  {isEdit}
      />
    </>
  );
};

export default UserManagement;
