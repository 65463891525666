import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
// Redux
import { connect } from "react-redux";
import { toggleCollapsedNav } from "../../../redux/action/Theme";

//Image
import fatalErrorImg from "../../../assets/dist/img/macaroni-fatal-error.png";

const Error410 = ({ toggleCollapsedNav }) => {
  useEffect(() => {
    toggleCollapsedNav(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <div className="hk-pg-body">
        <Row>
          <Col xl={7} lg={6} className="d-lg-block d-none">
            <div className="auth-content py-md-0 py-8">
              <Row>
                <Col xl={12} className="text-center">
                  <img
                    src={fatalErrorImg}
                    className="img-fluid w-sm-80 w-50"
                    alt="login"
                  />
                </Col>
              </Row>
            </div>
          </Col>
          <Col xl={5} lg={6} md={7} sm={10}>
            <div className="auth-content py-8">
              <div className="w-100">
                <Row>
                  <Col xxl={9} xl={8} lg={11}>
                    <p className="p-lg">
                      Sorry, this reset password link had already expired.
                    </p>
                    <Button variant="primary" as={Link} to="/auth/login" className="mt-4">
                      Back to homepage
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed } = theme;
  return { navCollapsed };
};

export default connect(mapStateToProps, { toggleCollapsedNav })(Error410);
