import { useState, useEffect, useRef } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  InputGroup,
  Form
} from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment";

const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;

const EditModal = (props) => {
  const [CCUDetail, setCCUDetail] = useState({});
  const [CCUId, setCCUId] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e) => {
    setCCUDetail({
      ...CCUDetail,
      [e.target.name]: e.target.value,
    });
  };

  const handleDateChange = (selectedDate) => {
    handleInputChange({
      target: {
        name: "installation_date",
        value: selectedDate,
      },
    });
  };

  const fetchCCUData = async (id) => {
    try {
      const response = await fetch(
        `${backendUri}/api/v0/company/ccus/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${props.Token}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setCCUDetail({
          voltage_thresholds_min: data.voltage_thresholds_min,
          voltage_thresholds_max: data.voltage_thresholds_max,

          temperature_thresholds_min: data.temperature_thresholds_min,
          temperature_thresholds_max: data.temperature_thresholds_max,

          resistance_1k_thresholds_min: data.resistance_1k_thresholds_min,
          resistance_1k_thresholds_max: data.resistance_1k_thresholds_max,

          contact_person: data.contact_person,
          phone_number: data.phone_number,
          type: data.type,
          capacity: data.capacity,
          installation_date: moment(data.installation_date).format("YYYY-MM-DD"),
          location: data.location,
          last_seen: data.last_seen
        })
        setCCUId(data.ccu_id);
      } else {
        console.error("Fail to fetch CCU data");
      }
    } catch (error) {
      console.error("Error fetching CCU Data")
    }
  };

  const handleUpdate = async(e) => {
    e.preventDefault();
    setIsSubmitting(true);

    let isTimedOut = false;

    const timeout = setTimeout(() => {
      isTimedOut = true;
      setIsSubmitting(false);
      toast.error("Request timed out. Please try again later.", {
        containerId: "error-container",
      });
      props.closeModal();
    }, 5000);

    console.log(CCUDetail)

    fetch(`${backendUri}/api/v0/company/ccus/${props.CCUId}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${props.Token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(CCUDetail),
    })
      .then((res) => {
        clearTimeout(timeout);
        return res.json();
      })
      .then((res) => {
        console.log(res.error);
        if (res.error) {
          toast.error(res.message, {
            containerId: "error-container",
          });
          props.closeModal();
        } else {
          toast.success(res.message, {
            containerId: "success-container",
          });
          props.fetchData(props.Token);
          props.closeModal();
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    fetchCCUData(props.CCUId);
  }, [props.showModal]);

  return (
    <>
      <Modal show={props.showModal} onHide={props.closeModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
                CCU Detail - {CCUId}
          </Modal.Title>
        </Modal.Header>
        <Form style={{ margin:3}} onSubmit={handleUpdate}>
          <Modal.Body>
            <Row className="mb-2">
              <Form.Group as={Col} controlId="contactPerson">
                <Form.Label> Contact Person </Form.Label>
                <InputGroup>
                  <Form.Control
                    name="contact_person"
                    type="text"
                    placeholder="Enter Contact Person"
                    value={CCUDetail.contact_person}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} controlId="phoneNumber">
                <Form.Label> Phone Number </Form.Label>
                <InputGroup>
                  <Form.Control
                    name="phone_number"
                    type="number"
                    placeholder="Enter Phone Number"
                    value={CCUDetail.phone_number}
                    onChange={handleInputChange}
                    min="1"
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <hr />
            <Row className="mb-2">
              <Form.Group as={Col} controlId="type">
                <Form.Label> Type </Form.Label>
                <InputGroup>
                  <Form.Control
                    name="type"
                    type="text"
                    placeholder="Enter Type"
                    value={CCUDetail.type}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} controlId="capacity">
                <Form.Label> Capacity </Form.Label>
                <InputGroup>
                  <Form.Control
                    name="capacity"
                    type="text"
                    placeholder="Enter Capacity"
                    value={CCUDetail.capacity}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-2">
              <Form.Group as={Col} controlId="location">
                <Form.Label> Location </Form.Label>
                <InputGroup>
                  <Form.Control
                    name="location"
                    type="text"
                    placeholder="Enter Location"
                    value={CCUDetail.location}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} controlId="installationDate">
                <Form.Label> Installation Date </Form.Label>
                <InputGroup>
                  <Form.Control
                    name="installation_date"
                    type="date"
                    value={CCUDetail.installation_date}
                    onChange={(e) => {
                      handleDateChange(e.target.value);
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <hr />
            <Row className="mb-2">
              <Form.Group as={Col} controlId="voltageThresholdsMin">
                <Form.Label> Voltage Thresholds (V), Min </Form.Label>
                <InputGroup>
                  <Form.Control
                    name="voltage_thresholds_min"
                    type="text"
                    placeholder="Enter Voltage Thresholds (V), Min"
                    value={CCUDetail.voltage_thresholds_min}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} controlId="voltageThresholdsMax">
                <Form.Label> Voltage Thresholds (V), Max </Form.Label>
                <InputGroup>
                  <Form.Control
                    name="voltage_thresholds_max"
                    type="text"
                    placeholder="Enter Voltage Thresholds (V), Max"
                    value={CCUDetail.voltage_thresholds_max}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <hr />
            <Row className="mb-2">
              <Form.Group as={Col} controlId="temperatureThresholdsMin">
                <Form.Label> Temperature Thresholds (ºC), Min </Form.Label>
                <InputGroup>
                  <Form.Control
                    name="temperature_thresholds_min"
                    type="text"
                    placeholder="Enter Temperature Thresholds (ºC), Min"
                    value={CCUDetail.temperature_thresholds_min}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} controlId="temperatureThresholdsMax">
                <Form.Label> Temperature Thresholds (ºC), Max </Form.Label>
                <InputGroup>
                  <Form.Control
                    name="temperature_thresholds_max"
                    type="text"
                    placeholder="Enter Temperature Thresholds (ºC), Max"
                    value={CCUDetail.temperature_thresholds_max}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <hr />
            <Row className="mb-2">
              <Form.Group as={Col} controlId="resistance1kThresholdsMin">
                <Form.Label> Internal Resistance Threshold (mΩ), Min </Form.Label>
                <InputGroup>
                  <Form.Control
                    name="resistance_1k_thresholds_min"
                    type="text"
                    placeholder="Enter Internal Resistance Threshold (mΩ), Min"
                    value={CCUDetail.resistance_1k_thresholds_min}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} controlId="resistance1kThresholdsMax">
                <Form.Label> Internal Resistance Threshold (mΩ), Max </Form.Label>
                <InputGroup>
                  <Form.Control
                    name="resistance_1k_thresholds_max"
                    type="text"
                    placeholder="Enter Internal Resistance Threshold (mΩ), Max"
                    value={CCUDetail.resistance_1k_thresholds_max}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="soft-primary"
              id="editSubmitBtn"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span className="visually-hidden">Submitting...</span>
                </>
              ) : (
                "Update"
              )}
            </Button>
            <Button
              variant="soft-secondary"
              onClick={props.closeModal}
              id="editCloseBtn"
            >
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
};

export default EditModal;