import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode"; // Import jwt_decode
import Cookies from "js-cookie";
import {useAuth} from "../services/authContext"

const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;

const useUserServices = () => {
  const [userAccount, setUserAccount] = useState("");
  const { user } = useAuth();
  // const timeout = setTimeout(() => {
  //   // isTimedOut = true;
  //   // setIsSubmitting(false);
  //   toast.error("Request timed out. Please try again later.", {
  //     containerId: "error-container",
  //   });
  //   // props.handleCloseForm();
  // }, 5000);
  const getAllUserAccounts = async () =>{
    try{
      const response = await fetch(
        `${backendUri}/api/v0/admin/users`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("BMStoken")}`,
          },
        }
      );

      const data = await response.json();
      if(response.ok){
        return { success: true, data };
      }else {
        return { success: false, data };
      }
    }catch(error){
      console.error("Fetch API fail", error);
      return { success: false, data: null }; // Return false for any errors during login
    }
  }

  const getUserAccount = async (userId) => {
    try {
      let role;
      user.role === 1? role = "admin" : role = "auth"

      const response = await fetch(
        `${backendUri}/api/v0/${role}/users/${userId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookies.get("BMStoken")}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        return { success: true, data };
      } else {
        return { success: false, data };
      }
    } catch (error) {
      console.error("Fetch API fail", error);
      return { success: false,  data: null }; // Return false for any errors during login
    }
  };


  // const getUserAccount = async (token) => {
  //   try {
  //     const decodedToken = jwtDecode(token);
  //     const response = await fetch(
  //       `${backendUri}/api/v0/user/account/${decodedToken.userId}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     const data = await response.json();
  //     if (response.ok) {
  //       setUserAccount(data);
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } catch (error) {
  //     console.error("Fetch API fail", error);
  //     return false; // Return false for any errors during login
  //   }
  // };

  const createUserAccount = async (user) => {
    try {
      const response = await fetch(`${backendUri}/api/v0/admin/users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("BMStoken")}`,
        },
        body: JSON.stringify(user),
      })

      const data = await response.json();

      if (response.ok) {
        toast.success(data.message, {
          containerId: "toast-container",
        });
        return true; // Return true for successful login
      } else {
        toast.error(data.message, {
          containerId: "toast-container",
        });
        return false; // Return false for failed login
      }
      // .finally(() => {
      //   resetVariable();
      //   setIsSubmitting(false);
      // });
    } catch (error) {
      console.error("Fetch API fail", error);
      return false; // Return false for any errors during login
    }
  };

  const updateUserAccount = async (user, userId) => {
    try {
      const response = await fetch(`${backendUri}/api/v0/admin/users/${userId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("BMStoken")}`,
        },
        body: JSON.stringify(user),
      })

      const data = await response.json();

      if (response.ok) {
        toast.success(data.message, {
          containerId: "toast-container",
        });
        return true; // Return true for successful login
      } else {
        toast.error(data.message, {
          containerId: "toast-container",
        });
        return false; // Return false for failed login
      }
      // .finally(() => {
      //   resetVariable();
      //   setIsSubmitting(false);
      // });
    } catch (error) {
      console.error("Fetch API fail", error);
      return false; // Return false for any errors during login
    }
  };

  const deleteUserAccount = async (userId) => {
    try{
      const response = await fetch(`${backendUri}/api/v0/admin/users/${userId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("BMStoken")}`,
        },
      })
      const data = await response.json();

      if (response.ok) {
        toast.success(data.message, {
          containerId: "toast-container",
        });
        return true; // Return true for successful login
      } else {
        toast.error(data.message, {
          containerId: "toast-container",
        });
        return false; // Return false for failed login
      }
    }catch(error){
      console.error("Fetch API fail", error);
      return false; // Return false for any errors during login
    }
  }


  return {
    getUserAccount,
    setUserAccount,
    userAccount,
    createUserAccount,
    getAllUserAccounts,
    updateUserAccount,
    deleteUserAccount
  };
};

export default useUserServices;
