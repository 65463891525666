import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Button,
  Row,
  Col,
  InputGroup,
  Spinner
} from "react-bootstrap";
import { Country, State } from "country-state-city";
import "react-toastify/dist/ReactToastify.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from "react-toastify";

const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;

const LinkModal = (props) => {
  const [CCUId, setCCUId] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    let isTimedOut = false;

    const timeout = setTimeout(() => {
      isTimedOut = true;
      setIsSubmitting(false);
      toast.error("Request timed out. Please try again later.", {
        containerId: "error-container",
      });
      props.closeModal();
    }, 5000);

    fetch(`${backendUri}/api/v0/company/ccus/link/${CCUId}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${props.Token}`,
      },
    })
      .then((res) => {
        clearTimeout(timeout);
        return res.json();
      })
      .then((res) => {
        console.log(res.error);
        if (res.error) {
          toast.error(res.message, {
            containerId: "error-container",
          });
          props.closeModal();
        } else {
          toast.success(res.message, {
            containerId: "success-container",
          });

          setCCUId("");

          props.fetchData(props.Token);
          props.closeModal();
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Modal show={props.showModal} onHide={props.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title> Link CCU </Modal.Title>
        </Modal.Header>
        <Form style={{ margin:3}} onSubmit={(e) => handleSubmit(e)}>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group controlId="ccuId">
                <Form.Label> CCU ID* </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="fa fa-tag" />
                  </InputGroup.Text>
                  <Form.Control 
                    required
                    type="text"
                    placeholder="Enter CCU ID"
                    value={CCUId}
                    onChange={(e) => {
                      setCCUId(e.target.value);
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="soft-primary"
              type="submit"
              id="linkSubmitBtn"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                    Submitting...
                </>
              ) : (
                "Submit"
              )}
            </Button>
            <Button
              variant="soft-secondary"
              onClick={props.closeModal}
              id="linkCloseBtn"
            >
                Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
};

export default LinkModal;