/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import { Button, Card, Nav } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import { connect } from "react-redux";
import { toggleCollapsedNav } from "../../redux/action/Theme";
import { NavLink, useRouteMatch } from "react-router-dom";
import SidebarHeader from "./SidebarHeader";
import { SidebarMenu, MenuItem } from "./SidebarMenu";
import classNames from "classnames";
import { useWindowWidth } from "@react-hook/window-size";
import { useAuth } from "../../services/authContext";

const Sidebar = ({ navCollapsed, toggleCollapsedNav }) => {
  const [activeMenu, setActiveMenu] = useState(() => {
    return localStorage.getItem("activeMenu") || "";
  });
  const [activeSubMenu, setActiveSubMenu] = useState();
  const [menuItem, setMenuItem] = useState([]);
  const { user } = useAuth();

  const windowWidth = useWindowWidth();

  const handleClick = (menuName) => {
    setActiveMenu(menuName);
    localStorage.setItem("activeMenu", menuName);
    if (activeMenu !== "Dashboard" && windowWidth >= 1200) {
      toggleCollapsedNav(true);
    } else if (windowWidth <= 1199) {
      toggleCollapsedNav(false);
    }

    if (menuName === "Dashboard") {
      toggleCollapsedNav(false);
    }
  };

  const backDropToggle = () => {
    toggleCollapsedNav(!navCollapsed);
  };

  useEffect(() => {
    setMenuItem(MenuItem(user.role));
  }, [user]);

  useEffect(() => {
    return () => {
      localStorage.removeItem("activeMenu");
    };
  }, []);

  // const currentRouteMatch = useRouteMatch();
  const currentRouteMatch = useRouteMatch();
  return (
    <>
      {/* <MyComponent /> */}
      <div className="hk-menu">
        {/* Brand */}
        <SidebarHeader />
        {/* Main Menu */}
        <SimpleBar className="nicescroll-bar" style={{backgroundColor: "#B6D0E2"}}>
          <div className="menu-content-wrap" >
            {menuItem.map((routes, index) => (
              <React.Fragment key={index}>
                <div className="menu-group">
                  {routes.group && (
                    <div className="nav-header">
                      <span>{routes.group}</span>
                    </div>
                  )}
                  {routes.contents.map((menus, idx) => (
                    <Nav
                      bsPrefix="navbar-nav"
                      activeClassName="active"
                      onClick={() => handleClick(menus.name)}
                      className="flex-column"
                      key={idx}
                    >
                      <Nav.Item
                        className={classNames({
                          active:
                            currentRouteMatch &&
                            currentRouteMatch.path === menus.path,
                        })}
                        style={{ 
                          fontWeight: activeMenu === menus.name ? "bold" : "normal",
                          fontSize: activeMenu === menus.name ? "1.2rem" : "1rem",
                        }}
                      >
                        {menus.childrens ? (
                          <>
                            <Nav.Link
                              data-bs-toggle="collapse"
                              data-bs-target={`#${menus.id}`}
                              aria-expanded={
                                activeMenu === menus.name ? "true" : "false"
                              }
                              onClick={() => setActiveMenu(menus.name)}
                            >
                              <span
                                className={classNames("nav-icon-wrap", {
                                  "position-relative": menus.iconBadge,
                                })}
                              >
                                {menus.iconBadge && menus.iconBadge}
                                <span className="svg-icon">{menus.icon}</span>
                              </span>
                              <span
                                className={classNames("nav-link-text", {
                                  "position-relative": menus.badgeIndicator,
                                })}
                              >
                                {menus.name}
                                {menus.badgeIndicator && menus.badgeIndicator}
                              </span>
                              {menus.badge && menus.badge}
                            </Nav.Link>

                            {/* <Collapse in={open}> */}
                            <ul
                              id={menus.id}
                              className={classNames(
                                "nav flex-column nav-children",
                                { collapse: activeMenu !== menus.name }
                              )}
                            >
                              <li className="nav-item">
                                <ul className="nav flex-column">
                                  {menus.childrens.map((subMenu, indx) =>
                                    subMenu.childrens ? (
                                      <li className="nav-item" key={indx}>
                                        <Nav.Link
                                          as={NavLink}
                                          to={subMenu.path}
                                          className="nav-link"
                                          data-bs-toggle="collapse"
                                          data-bs-target={`#${subMenu.id}`}
                                          aria-expanded={
                                            activeSubMenu === subMenu.name
                                              ? "true"
                                              : "false"
                                          }
                                          onClick={() =>
                                            setActiveSubMenu(subMenu.name)
                                          }
                                        >
                                          <span className="nav-link-text">
                                            {subMenu.name}
                                          </span>
                                        </Nav.Link>

                                        {subMenu.childrens.map(
                                          (childrenPath, i) => (
                                            <ul
                                              id={subMenu.id}
                                              className={classNames(
                                                "nav flex-column nav-children",
                                                {
                                                  collapse:
                                                    activeSubMenu !==
                                                    subMenu.name,
                                                }
                                              )}
                                              key={i}
                                            >
                                              <li className="nav-item">
                                                <ul className="nav flex-column">
                                                  <li className="nav-item">
                                                    <Nav.Link
                                                      as={NavLink}
                                                      to={childrenPath.path}
                                                      onClick={handleClick}
                                                    >
                                                      <span className="nav-link-text">
                                                        {childrenPath.name}
                                                      </span>
                                                    </Nav.Link>
                                                  </li>
                                                </ul>
                                              </li>
                                            </ul>
                                          )
                                        )}
                                      </li>
                                    ) : (
                                      <li className="nav-item" key={indx}>
                                        <Nav.Link
                                          as={NavLink}
                                          to={subMenu.path}
                                          onClick={handleClick}
                                        >
                                          <span className="nav-link-text">
                                            {subMenu.name}
                                          </span>
                                        </Nav.Link>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </li>
                            </ul>
                            {/* </Collapse> */}
                          </>
                        ) : (
                          <>
                            {routes.group === "Documentation" ? (
                              <a
                                className="nav-link"
                                href={menus.path}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <span className="nav-icon-wrap">
                                  <span className="svg-icon">{menus.icon}</span>
                                </span>
                                <span className="nav-link-text">
                                  {menus.name}
                                </span>
                                {menus.badge && menus.badge}
                              </a>
                            ) : (
                              <>
                                {menus.path !== "/" ? (
                                  <Nav.Link
                                    as={NavLink}
                                    exact={true}
                                    activeClassName="active"
                                    to={menus.path}
                                    onClick={() => handleClick(menus.name)}
                                  >
                                    <span className="nav-icon-wrap">
                                      <span className="svg-icon">
                                        {menus.icon}
                                      </span>
                                    </span>
                                    <span className="nav-link-text">
                                      {menus.name}
                                    </span>
                                    {menus.badge && menus.badge}
                                  </Nav.Link>
                                ) : (
                                  <>
                                    <Nav.Link to={menus.path}>
                                      <span
                                        className="nav-icon-wrap"
                                        style={{ color: "grey" }}
                                      >
                                        <span className="svg-icon">
                                          {menus.icon}
                                        </span>
                                      </span>
                                      <span
                                        className="nav-link-text"
                                        style={{ color: "grey" }}
                                      >
                                        {menus.name}
                                      </span>
                                      {menus.badge && menus.badge}
                                    </Nav.Link>
                                  </>

                                // <span className="nav-link-text">
                                //   {menus.name}
                                //   {menus.badge && menus.badge}
                                // </span>
                                )}
                              </>

                            // <Nav.Link
                            //   as={NavLink}
                            //   exact={true}
                            //   activeClassName="active"
                            //   to={menus.path}
                            //   onClick={() => handleClick(menus.name)}
                            // >
                            //   <span className="nav-icon-wrap">
                            //     <span className="svg-icon">{menus.icon}</span>
                            //   </span>
                            //   <span className="nav-link-text">
                            //     {menus.name}
                            //   </span>
                            //   {menus.badge && menus.badge}
                            // </Nav.Link>
                            )}
                          </>
                        )}
                      </Nav.Item>
                    </Nav>
                  ))}
                </div>
                <div className="menu-gap" />
              </React.Fragment>
            ))}
          </div>
        </SimpleBar>
        {/* /Main Menu */}
      </div>
      <div onClick={backDropToggle} className="hk-menu-backdrop" />
    </>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed } = theme;
  return { navCollapsed };
};

export default connect(mapStateToProps, { toggleCollapsedNav })(Sidebar);
