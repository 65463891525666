import { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  TabContent,
  Table,
  Form,
  InputGroup,
} from "react-bootstrap";
import { Plus } from "react-feather";
import { ToastContainer } from "react-toastify";
import AddCompanyModal from "./AddCompanyModal";
import EditCompanyModal from "./EditCompanyModal";
import DeleteCompanyModal from "./DeleteCompanyModal";
import Cookies from "js-cookie";
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-thumbnail.css';
import image from '../../assets/dist/img/no-image.svg';

const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;

const CompanyManagement = () => {
  const [Token, setToken] = useState(Cookies.get("BMStoken"));

  const [lists, setList] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredList, setFilteredLists] = useState([...lists]);

  const [showAddForm, setShowAddForm] = useState(false);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);

  const [companyId, setCompanyId] = useState(null);

  const handleAddOpen = () => {
    setShowAddForm(true);
  };
  const handleAddClose = () => {
    setShowAddForm(false);
  };

  const handleEditOpen = (id) => {
    setCompanyId(id);
    setShowEditForm(true);
  };
  const handleEditClose = () => {
    setShowEditForm(false);
  };

  const handleDeleteOpen = (id) => {
    setCompanyId(id);
    setShowDeleteForm(true);
  };
  const handleDeleteClose = (id) => {
    setShowDeleteForm(false);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = filteredList.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (searchTerm) {
      const filterItems = lists.filter(
        (item) =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.address.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.contact.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredLists(filterItems);
    } else {
      setFilteredLists([...lists]);
    }
  }, [lists, searchTerm]);

  useEffect(() => {
    fetchData(Token);
  }, []);

  const fetchData = async (Token) => {
    await fetch(`${backendUri}/api/v0/admin/companies`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data && data.length > 0) {
          setList(data);
        } else {
          setList([]);
        }
      })
      .catch((err) => {
        console.error("Login Failed due to: " + err.message);
      });
  };

  return (
    <>
      <Container>
        <TabContent>
          <Card
            className="card-border mb-0 h-100"
          >
            <Card.Header className="card-header-action">
              <h5>Company Management</h5>
              <Button
                variant="primary btn-animated"
                size="sm"
                className="ms-3"
                id="addCompanyBtn"
                onClick={handleAddOpen}
              >
                <span>
                  <span className="icon">
                    <span className="feather-icon">
                      <Plus />
                    </span>
                  </span>
                  <span className="bn-text" size="lg">
                    Add new
                  </span>
                </span>
              </Button>
            </Card.Header>
            <Card.Body>
              <InputGroup className="mb-4">
                <Form.Control 
                  placeholder="Search..."
                  className="table-search"
                  type="text"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </InputGroup>
              <div className="invoice-list-view">
                <div className="table-responsive">
                  <Table hover className="mb-0">
                    <thead>
                      <tr>
                        <th>Company Logo</th>
                        <th>Company Name</th>
                        <th>Address</th>
                        <th>Contact</th>
                        <th>Email</th>
                        <th>Created By</th>
                        <th>Updated By</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody id="dataTable">
                      {currentItems.map((item) => (
                        <tr key={item.id}>
                          <td>{item.logo ? (
                            <LightGallery
                              elementClassNames="image-container"
                              speed={500}
                              mode="lg-fade"
                              plugins={[lgZoom, lgThumbnail]}
                              thumbnail={false}
                              subHtmlSelectorRelative={true}
                              defaultCaptionHeight="100%"
                              download={false}
                            >
                              <img
                                src={`${item.logo}?timestamp=${Date.now()}`}
                                alt="logo"
                                style={{
                                  width: '75px',
                                  height: '75px',
                                  objectFit: 'cover',
                                  borderRadius: '50%',
                                  cursor: 'pointer',
                                }}
                              />
                            </LightGallery>
                          ) : (
                            <img
                              src={image}
                              alt="default"
                              style={{
                                width: '75px',
                                height: '75px',
                                objectFit: 'cover',
                              }}
                            />
                          )}</td>
                          <td>{item.name}</td>
                          <td>{item.address}</td>
                          <td>{item.contact}</td>
                          <td>{item.email}</td>
                          <td>{item.created_by}</td>
                          <td>{item.updated_by}</td>
                          <td>
                            <Button
                              id="editCompanyBtn"
                              size="sm"
                              variant="soft-primary"
                              style={{ margin: 5 }}
                              className="btn-rounded"
                              onClick={() => {
                                handleEditOpen(item._id);
                              }}
                            >
                              Edit
                            </Button>
                            <Button
                              id="deleteCompanyBtn"
                              size="sm"
                              variant="soft-danger"
                              style={{ margin: 5 }}
                              className="btn-rounded"
                              onClick={() => {
                                handleDeleteOpen(item._id);
                              }}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Row>
                    <Col
                      md={{ span: 2, offset: 10 }}
                      xs={{ span: 12, offset: 0 }}
                      className="text-center mt-3"
                    >
                      <Button
                        variant="primary btn-animated"
                        className="btn-icon"
                        disabled={currentPage === 1}
                        onClick={() => paginate(currentPage - 1)}
                      >
                        <span>
                          <i className="ri-arrow-left-s-line" />
                        </span>
                      </Button>
                      <span className="mx-1">{currentPage}</span>
                      <Button
                        variant="primary btn-animated "
                        className="btn-icon"
                        disabled={indexOfLastItem >= lists.length}
                        onClick={() => paginate(currentPage + 1)}
                      >
                        <span>
                          <i className="ri-arrow-right-s-line" />
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card.Body>
          </Card>
        </TabContent>
      </Container>
      <ToastContainer
        position="top-right"
        style={{ top: "70px", right: "20px" }}
        enableMultiContainer
        containerId="error-container"
      />
      <ToastContainer
        position="top-right"
        style={{ top: "70px", right: "20px" }}
        enableMultiContainer
        containerId="success-container"
      />
      <AddCompanyModal
        showForm={showAddForm}
        handleCloseForm={handleAddClose}
        Token={Token}
        fetchData={fetchData}
      />
      <EditCompanyModal
        showForm={showEditForm}
        handleCloseForm={handleEditClose}
        Token={Token}
        fetchData={fetchData}
        companyId={companyId}
      />
      <DeleteCompanyModal
        showForm={showDeleteForm}
        handleCloseForm={handleDeleteClose}
        Token={Token}
        fetchData={fetchData}
        companyId={companyId}
      />
    </>
  )
};

export default CompanyManagement;