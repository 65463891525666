import React, { useEffect, useState } from "react";
import "bootstrap-daterangepicker/daterangepicker.css";
import Chart from "chart.js/auto";
import "jspdf-autotable";
import {registerables } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
Chart.register(...registerables, zoomPlugin)
const GraphDisplay = ({
  ccuBatteries,
  ccuData,
  voltageChartContainer,
  ccuCurrentChartContainer,
  resistanceChartContainer,
  temperatureChartContainer,
  selectedCcu,
  // selectedCcus,
  // selectedBatteries,
}) => {
  //Chart instances
  const [voltageChartInstance, setVoltageChartInstance] = useState();
  const [temperatureChartInstance, setTemperatureChartInstance] = useState();
  const [resistanceChartInstance, setResistanceChartInstance] = useState();
  const [ccuCurrentChartInstance, setCcuCurrentChartInstance] = useState();

  // //To store the selected batteries for graph for compare purpose
  // const [selectedBatteriesForGraph, setSelectedBatteriesForGraph] = useState();

  // //To store the selected ccu for graph for compare purpose
  const [selectedCcuForGraph, setSelectedCcuForGraph] = useState();
  const [batteryObjectLength, setBatteryObjectLength] = useState();
  const [ccuObjectLength, setCcuObjectLength] = useState();

  // const renderCcuCurrentGraph = (newCcuData) => {
  //   const ctx = ccuCurrentChartContainer.current.getContext("2d");
  //   const groupedCurrent = newCcuData;

  //   // Prepare datasets for Chart.js
  //   const datasets = Object.keys(groupedCurrent).map((ccuId) => {
  //     const data = groupedCurrent[ccuId]
  //       .map((item) => {
  //         return {
  //           x: item.time,
  //           y: item.system_current,
  //         };
  //       })
  //       .filter(Boolean); // Filter out null or undefined values
  //     return {
  //       label: `${ccuId}`,
  //       data: data,
  //       borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`, // Random color
  //       borderWidth: 2,
  //       fill: false,
  //     };
  //   });

  //   if (ccuCurrentChartInstance) {
  //     ccuCurrentChartInstance.destroy();
  //   }

  //   // Render the graph using Chart.js
  //   const newCcuCurrentChartInstance = new Chart(ctx, {
  //     type: "line",
  //     data: {
  //       datasets: datasets,
  //     },
  //     options: {
  //       plugins: {
  //         title: {
  //           display: true,
  //           text: `CCU Current (A)`, // Title text
  //           font: {
  //             size: 18, // Title font size
  //           },
  //         },
  //       },
  //       scales: {
  //         y: {
  //           beginAtZero: true,
  //           title: {
  //             display: true,
  //             text: "Current (A)",
  //           },
  //         },

  //         x: {
  //           type: "time",
  //           time: {
  //             unit: "hour", // Set unit to hour
  //             displayFormats: {
  //               hour: "DD MMM h A", // Format for hour
  //             },
  //           },
  //           ticks: {
  //             // autoSkip: false, // Disable automatic skipping of ticks
  //             // maxTicksLimit: null, // Display all ticks
  //             source: "auto",
  //             autoSkip: true,
  //             maxTicksLimit: 7,
  //             maxRotation: 0,
  //             minRotation: 0,
  //           },
  //         },
  //       },
  //     },
  //   });

  //   setCcuCurrentChartInstance(newCcuCurrentChartInstance);
  // };

  const renderCcuCurrentGraph = (newCcuData) => {
    const ctx = ccuCurrentChartContainer.current.getContext("2d");
    const groupedCurrent = newCcuData;
  
    // Prepare datasets for Chart.js
    const datasets = Object.keys(groupedCurrent).map((ccuId) => {
      const data = groupedCurrent[ccuId]
        .map((item) => {
          return {
            x: item.time,
            y: item.system_current,
          };
        })
        .filter(Boolean); // Filter out null or undefined values
      return {
        label: `${ccuId}`,
        data: data,
        borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`, // Random color
        borderWidth: 2,
        fill: false,
      };
    });
  
    if (ccuCurrentChartInstance) {
      ccuCurrentChartInstance.destroy();
    }
  
    // Render the graph using Chart.js
    const newCcuCurrentChartInstance = new Chart(ctx, {
      type: "line",
      data: {
        datasets: datasets,
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: `CCU Current (A)`, // Title text
            font: {
              size: 18, // Title font size
            },
          },
          zoom: {
            zoom: {
              wheel: {
                enabled: true, // Enable zooming with the mouse wheel
              },
              pinch: {
                enabled: true, // Enable zooming with pinch gestures
              },
              mode: "x", // Allow zooming on both axes
            },
            pan: {
              enabled: true, // Enable panning
              mode: "x", // Allow panning on both axes
            },
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: "Current (A)",
            },
          },
          x: {
            type: "time",
            time: {
              unit: "hour", // Set unit to hour
              displayFormats: {
                hour: "DD MMM h A", // Format for hour
              },
            },
            ticks: {
              // autoSkip: false, // Disable automatic skipping of ticks
              // maxTicksLimit: null, // Display all ticks
              source: "auto",
              autoSkip: true,
              maxTicksLimit: 7,
              maxRotation: 0,
              minRotation: 0,
            },
          },
        },
      },
    });
  
    setCcuCurrentChartInstance(newCcuCurrentChartInstance);
  };
  

  const renderVoltageGraph = (newBatteriesData) => {
    const ctx = voltageChartContainer.current.getContext("2d");

    const groupedVoltage = newBatteriesData;

    // Prepare datasets for Chart.js
    const datasets = Object.keys(groupedVoltage).map((batteryId) => {
      const data = groupedVoltage[batteryId]
        .map((item) => {
          return {
            x: item.time,
            y: item.voltage,
          };
        })
        .filter(Boolean); // Filter out null or undefined values
      return {
        label: `Battery ${batteryId}`,
        data: data,
        borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`, // Random color
        borderWidth: 2,
        fill: false,
      };
    });

    if (voltageChartInstance) {
      voltageChartInstance.destroy();
    }

    // Render the graph using Chart.js
    const newVoltageChartInstance = new Chart(ctx, {
      type: "line",
      data: {
        datasets: datasets,
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: `Voltage (V), ${selectedCcu}`, // Title text
            font: {
              size: 18, // Title font size
            },
          },
          zoom: {
            zoom: {
              wheel: {
                enabled: true, // Enable zooming with the mouse wheel
              },
              pinch: {
                enabled: true, // Enable zooming with pinch gestures
              },
              mode: "x", // Allow zooming on both axes
            },
            pan: {
              enabled: true, // Enable panning
              mode: "x", // Allow panning on both axes
            },
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: "Voltage (V)",
            },
          },

          x: {
            // type: "time",
            // time: {
            //   unit: "day",
            //   displayFormats: {
            //     day: "MMM DD",
            //   },
            type: "time",
            time: {
              unit: "hour", // Set unit to hour
              displayFormats: {
                hour: "DD MMM h A", // Format for hour
              },
            },
            ticks: {
              // autoSkip: false, // Disable automatic skipping of ticks
              // maxTicksLimit: null, // Display all ticks
              source: "auto",
              autoSkip: true,
              maxTicksLimit: 7,
              maxRotation: 0,
              minRotation: 0,
            },
          },
        },
      },
    });

    setVoltageChartInstance(newVoltageChartInstance);
  };

  const renderTemperatureGraph = (newBatteriesData) => {
    const ctx = temperatureChartContainer.current.getContext("2d");

    const groupedTemperature = newBatteriesData;

    // Prepare datasets for Chart.js
    const datasets = Object.keys(groupedTemperature).map((batteryId) => {
      const data = groupedTemperature[batteryId]
        .map((item) => {
          // Check if item time is within the specified range
          //   let actualEndDate = new Date(endDate);
          //   actualEndDate.setDate(actualEndDate.getDate() + 1);

          return {
            x: item.time,
            y: item.temperature,
          };
        })
        .filter(Boolean); // Filter out null or undefined values
      return {
        label: `Battery ${batteryId}`,
        data: data,
        borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`, // Random color
        borderWidth: 2,
        fill: false,
      };
    });

    if (temperatureChartInstance) {
      temperatureChartInstance.destroy();
    }

    // Render the graph using Chart.js
    const newTemperatureChartInstance = new Chart(ctx, {
      type: "line",
      data: {
        datasets: datasets,
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: `Temperature (ºC), ${selectedCcu}`, // Title text
            font: {
              size: 18, // Title font size
            },
          },
          zoom: {
            zoom: {
              wheel: {
                enabled: true, // Enable zooming with the mouse wheel
              },
              pinch: {
                enabled: true, // Enable zooming with pinch gestures
              },
              mode: "x", // Allow zooming on both axes
            },
            pan: {
              enabled: true, // Enable panning
              mode: "x", // Allow panning on both axes
            },
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: "Temperature (°C)",
            },
          },

          // x: {
          //   type: "time",
          //   time: {
          //     unit: "day",
          //     displayFormats: {
          //       day: "MMM DD",
          //     },
          //   },
          //   ticks: {
          //     source: "auto",
          //     autoSkip: true,
          //     maxTicksLimit: 7,
          //     maxRotation: 0,
          //     minRotation: 0,
          //   },
          // },
          x: {
            // type: "time",
            // time: {
            //   unit: "day",
            //   displayFormats: {
            //     day: "MMM DD",
            //   },
            type: "time",
            time: {
              unit: "hour", // Set unit to hour
              displayFormats: {
                hour: "DD MMM h A", // Format for hour
              },
            },
            ticks: {
              source: "auto",
              autoSkip: true,
              maxTicksLimit: 7,
              maxRotation: 0,
              minRotation: 0,
            },
          },
        },
      },
    });
    setTemperatureChartInstance(newTemperatureChartInstance);
  };

  const renderResistanceGraph = (newBatteriesData) => {
    const ctx = resistanceChartContainer.current.getContext("2d");

    const groupedResistance = newBatteriesData;

    // Prepare datasets for Chart.js
    const datasets = Object.keys(groupedResistance).map((batteryId) => {
      const data = groupedResistance[batteryId]
        .map((item) => {
          return {
            x: item.time,
            y: item.resistance_1k,
          };
        })
        .filter(Boolean); // Filter out null or undefined values
      return {
        label: `Battery ${batteryId}`,
        data: data,
        borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`, // Random color
        borderWidth: 2,
        fill: false,
      };
    });

    if (resistanceChartInstance) {
      resistanceChartInstance.destroy();
    }
    // Render the graph using Chart.js
    const newResistanceChartInstance = new Chart(ctx, {
      type: "line",
      data: {
        datasets: datasets,
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: `Internal Resistance (Ω), ${selectedCcu}`, // Title text
            font: {
              size: 18, // Title font size
            },
          },
          zoom: {
            zoom: {
              wheel: {
                enabled: true, // Enable zooming with the mouse wheel
              },
              pinch: {
                enabled: true, // Enable zooming with pinch gestures
              },
              mode: "x", // Allow zooming on both axes
            },
            pan: {
              enabled: true, // Enable panning
              mode: "x", // Allow panning on both axes
            },
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: "Resistance (Ω)",
            },
          },

          x: {
            type: "time",
            time: {
              unit: "hour", // Set unit to hour
              displayFormats: {
                hour: "DD MMM h A", // Format for hour
              },
            },
            ticks: {
              source: "auto",
              autoSkip: true,
              maxTicksLimit: 7,
              maxRotation: 0,
              minRotation: 0,
            },
          },
        },
      },
    });

    setResistanceChartInstance(newResistanceChartInstance);
  };

  useEffect(() => {
    if (typeof ccuData === "object" && Object.keys(ccuData).length > 0) {
      // Sort the keys of newCcuData

      const sortedKeys = Object.keys(ccuData).sort();

      // Create a new object with sorted keys
      let sortedCcuData = {};
      sortedKeys.forEach((key) => {
        sortedCcuData[key] = ccuData[key];
      });

      if (
        ccuCurrentChartInstance &&
        Object.keys(sortedCcuData).length === ccuObjectLength
      ) {
        ccuCurrentChartInstance.data.datasets.forEach((dataset, index) => {
          const ccuId = sortedKeys[index];

          if (sortedCcuData[ccuId]) {
            const newData = sortedCcuData[ccuId].map((item) => ({
              x: item.time,
              y: item.system_current,
            }));
            dataset.data = newData;
          }
        });
        ccuCurrentChartInstance.update();
      } else {
        renderCcuCurrentGraph(sortedCcuData);
        // setCcuObjectLength(Object.keys(sortedCcuData).length);

      }
    } else {
      

      if (ccuCurrentChartInstance) {
        ccuCurrentChartInstance.destroy();
      }
    }
    setCcuObjectLength(Object.keys(ccuData).length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ccuData]);
  

  useEffect(() => {
    // if (Object.keys(ccuBatteries).length > 0) {
    if (
      typeof ccuBatteries === "object" &&
      Object.keys(ccuBatteries).length > 0
    ) {
      const sortedKeys = Object.keys(ccuBatteries).sort();
      let sortedBatteriesData = {};
      sortedKeys.forEach((key) => {
        sortedBatteriesData[key] = ccuBatteries[key];
      });

      if (
        voltageChartInstance &&
        temperatureChartInstance &&
        resistanceChartInstance &&
        selectedCcu === selectedCcuForGraph &&
        Object.keys(sortedBatteriesData).length === batteryObjectLength
      ) {
        voltageChartInstance.data.datasets.forEach((dataset, index) => {
          const batteriesId = sortedKeys[index];

          if (sortedBatteriesData[batteriesId]) {
            const newData = sortedBatteriesData[batteriesId].map((item) => ({
              x: item.time,
              y: item.voltage,
            }));
            dataset.data = newData;
          }
        });

        temperatureChartInstance.data.datasets.forEach((dataset, index) => {
          const batteriesId = sortedKeys[index];

          if (sortedBatteriesData[batteriesId]) {
            const newData = sortedBatteriesData[batteriesId].map((item) => ({
              x: item.time,
              y: item.temperature,
            }));
            dataset.data = newData;
          }
        });

        resistanceChartInstance.data.datasets.forEach((dataset, index) => {
          const batteriesId = sortedKeys[index];

          if (sortedBatteriesData[batteriesId]) {
            const newData = sortedBatteriesData[batteriesId].map((item) => ({
              x: item.time,
              y: item.resistance_1k,
            }));
            dataset.data = newData;
          }
        });
        voltageChartInstance.update();
        resistanceChartInstance.update();
        temperatureChartInstance.update();
      } else {
        renderVoltageGraph(sortedBatteriesData);
        renderTemperatureGraph(sortedBatteriesData);
        renderResistanceGraph(sortedBatteriesData);
      }
    } else {
      if (voltageChartInstance) {
        voltageChartInstance.destroy();
      }

      if (temperatureChartInstance) {
        temperatureChartInstance.destroy();
      }

      if (resistanceChartInstance) {
        resistanceChartInstance.destroy();
      }
    }
    setBatteryObjectLength(Object.keys(ccuBatteries).length);
    setSelectedCcuForGraph(selectedCcu);
  }, [ccuBatteries]);

  return (
    <>
      {" "}
      {Object.keys(ccuBatteries).length !== 0 ||
      Object.keys(ccuData).length !== 0 ? (
        <>
          {" "}
          {selectedCcu && Object.keys(ccuBatteries).length !== 0 ? (
            <>
              <div style={{ marginBottom: "20px" }}>
                <canvas ref={voltageChartContainer} />
              </div>
              <div style={{ marginBottom: "20px" }}>
                <canvas ref={temperatureChartContainer} />
              </div>
              <div style={{ marginBottom: "20px" }}>
                <canvas ref={resistanceChartContainer} />
              </div>
            </>
          ) : null}
          {Object.keys(ccuData).length !== 0 ? (
            <>
              {" "}
              <div style={{ marginBottom: "20px" }}>
                <canvas ref={ccuCurrentChartContainer} />
              </div>
            </>
          ) : null}
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh", // Adjust the height to center vertically
              color: "black",
              fontSize: "24px", // Adjust the font size
            }}
          >
            No Data Available
          </div>
        </>
      )}
    </>
  );
};

export default GraphDisplay;
