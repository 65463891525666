import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode"; // Import jwt_decode
import Cookies from "js-cookie";
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;

const useCompanyServices = () => {
  const getAllCompanies = async () => {
    try {
      const response = await fetch(`${backendUri}/api/v0/admin/companies`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("BMStoken")}`,
        },
      });

      const data = await response.json();
      if (response.ok) {
        return { success: true, data };
      } else {
        return { success: false, data };
      }
    } catch (error) {
      console.error("Fetch API fail", error);
      return { success: false, data: null }; // Return false for any errors during login
    }
  };

  return {
    getAllCompanies
  };
};

export default useCompanyServices;
