import { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  TabContent,
  Table,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { Link } from "react-feather";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";

const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;

const BatteryManagement = () => {
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split('/');
  const dynamicParam = urlParts[urlParts.length - 1];

  const [Token, setToken] = useState(Cookies.get("BMStoken"));

  const [lists, setList] = useState([]);
  const [thresholds, setThresholds] = useState({});

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredList, setFilteredLists] = useState([...lists]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(30);

  const [showAlertedRows, setShowAlertedRows] = useState(false);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = filteredList.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const fetchData = async (Token) => {
    await fetch(`${backendUri}/api/v0/company/battery/${dynamicParam}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data && data.length > 0) {
          setList(data);
        } else {
          setList([]);
        }
      })
      .catch((err) => {
        console.error("Login Failed due to: " + err.message);
      });
  };

  const fetchCCUData = async (Token) => {
    try {
      const response = await fetch(
        `${backendUri}/api/v0/company/ccus/${dynamicParam}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setThresholds({
          voltage_thresholds_min: data.voltage_thresholds_min,
          voltage_thresholds_max: data.voltage_thresholds_max,
          temperature_thresholds_min: data.temperature_thresholds_min,
          temperature_thresholds_max: data.temperature_thresholds_max,
          resistance_1k_thresholds_min: data.resistance_1k_thresholds_min,
          resistance_1k_thresholds_max: data.resistance_1k_thresholds_max,
        })
        console.log("Thresholds", thresholds)
      } else {
        console.error("Fail to fetch CCU data");
      }
    } catch (error) {
      console.error("Error fetching CCU Data")
    }
  };

  useEffect(() => {
    if (searchTerm) {
      const filterItems = lists.filter(
        (item) =>
          item.battery_id.toString().includes(searchTerm.toString())
      );
      setFilteredLists(filterItems);
    } else {
      setFilteredLists([...lists]);
    }
  }, [lists, searchTerm]);

  useEffect(() => {
    const fetchDataAndCCU = () => {
      fetchData(Token);
      fetchCCUData(Token);
    };

    fetchDataAndCCU();

    const intervalId = setInterval(fetchDataAndCCU, 5 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  const tooltip = <Tooltip>Show Problematic Batteries</Tooltip>;

  const filteredRows = showAlertedRows
    ? currentItems.filter(
      (item) =>
        item.voltage < thresholds.voltage_thresholds_min ||
          item.voltage > thresholds.voltage_thresholds_max ||
          item.temperature < thresholds.temperature_thresholds_min ||
          item.temperature > thresholds.temperature_thresholds_max ||
          item.resistance_1k < thresholds.resistance_1k_thresholds_min ||
          item.resistance_1k > thresholds.resistance_1k_thresholds_max
    )
    : currentItems;

  return (
    <>
      <Container>
        <TabContent>
          <Card className="card-border mb-0 h-100">
            <Card.Header className="card-header-action">
              <Row className="align-items-center justify-content-between">
                <Col>
                  <h5>Battery View</h5>
                </Col>
                <Col xs="auto" className="text-right">
                  <OverlayTrigger placement="top" overlay={tooltip}>
                    <Form.Check
                      type="switch"
                      id="showAlertedRows"
                      checked={showAlertedRows}
                      onChange={() => setShowAlertedRows(!showAlertedRows)}
                    />
                  </OverlayTrigger>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <InputGroup className="mb-2">
                <Form.Control 
                  placeholder="Search..."
                  className="table-search"
                  type="text"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </InputGroup>
              <div className="invoice-list-view">
                <div className="table-responsive">
                  {lists.length !== 0 ? (
                    <Table hover className="mb-0" size="sm">
                      <thead>
                        <tr>
                          <th>Battery ID</th>
                          <th>Voltage (V)</th>
                          <th>Temperature (ºC)</th>
                          <th>Internal Resistance (mΩ)</th>
                          <th>Voltage Thresholds (V), Min</th>
                          <th>Voltage Thresholds (V), Max</th>
                          <th>Temperature Thresholds (ºC), Min</th>
                          <th>Temperature Thresholds (ºC), Max</th>
                          <th>Internal Resistance Threshold (mΩ), Min</th>
                          <th>Internal Resistance Threshold (mΩ), Max</th>
                          <th>Alert</th>
                          <th>Balancing Status</th>
                        </tr>
                      </thead>
                      <tbody id="dataTable">
                        {filteredRows.map((item) => (
                          <tr key={item.id}>
                            <td>{item.battery_id}</td>
                            <td style={{ 
                              color: (item.voltage < thresholds.voltage_thresholds_min || item.voltage > thresholds.voltage_thresholds_max) ? '#f50202' : '',
                              fontWeight: (item.voltage < thresholds.voltage_thresholds_min || item.voltage > thresholds.voltage_thresholds_max) ? 'bold' : 'normal'
                            }}
                            className={(item.voltage < thresholds.voltage_thresholds_min || item.voltage > thresholds.voltage_thresholds_max) ? "table-danger" : ""}
                            >
                              {item.voltage}
                            </td>
                            <td style={{ 
                              color: (item.temperature < thresholds.temperature_thresholds_min || item.temperature > thresholds.temperature_thresholds_max) ? '#f50202' : '',
                              fontWeight: (item.temperature < thresholds.temperature_thresholds_min || item.temperature > thresholds.temperature_thresholds_max) ? 'bold' : 'normal'
                            }}
                            className={(item.temperature < thresholds.temperature_thresholds_min || item.temperature > thresholds.temperature_thresholds_max) ? "table-danger" : ""}>
                              {item.temperature}
                            </td>
                            <td style={{ 
                              color: (item.resistance_1k < thresholds.resistance_1k_thresholds_min || item.resistance_1k > thresholds.resistance_1k_thresholds_max) ? '#f50202' : '',
                              fontWeight: (item.resistance_1k < thresholds.resistance_1k_thresholds_min || item.resistance_1k > thresholds.resistance_1k_thresholds_max) ? 'bold' : 'normal'
                            }}
                            className={(item.resistance_1k < thresholds.resistance_1k_thresholds_min || item.resistance_1k > thresholds.resistance_1k_thresholds_max) ? "table-danger" : ""}>
                              {item.resistance_1k}
                            </td>
                            <td>{thresholds.voltage_thresholds_min}</td>
                            <td>{thresholds.voltage_thresholds_max}</td>
                            <td>{thresholds.temperature_thresholds_min}</td>
                            <td>{thresholds.temperature_thresholds_max}</td>
                            <td>{thresholds.resistance_1k_thresholds_min}</td>
                            <td>{thresholds.resistance_1k_thresholds_max}</td>
                            <td>
                              {item.voltage !== null && item.voltage !== undefined &&
                             item.temperature !== null && item.temperature !== undefined &&
                             item.resistance_1k !== null && item.resistance_1k !== undefined && 
                             thresholds.voltage_thresholds_min !== null && thresholds.voltage_thresholds_min !== undefined &&
                             thresholds.voltage_thresholds_max !== null && thresholds.voltage_thresholds_max !== undefined &&
                             thresholds.temperature_thresholds_min !== null && thresholds.temperature_thresholds_min !== undefined &&
                             thresholds.temperature_thresholds_max !== null && thresholds.temperature_thresholds_max !== undefined &&
                             thresholds.resistance_1k_thresholds_min !== null && thresholds.resistance_1k_thresholds_min !== undefined &&
                             thresholds.resistance_1k_thresholds_max !== null && thresholds.resistance_1k_thresholds_max !== undefined ? (
                                  (thresholds.voltage_thresholds_min <= item.voltage &&
                                  item.voltage <= thresholds.voltage_thresholds_max) &&
                                (thresholds.temperature_thresholds_min <= item.temperature &&
                                  item.temperature <= thresholds.temperature_thresholds_max) &&
                                (thresholds.resistance_1k_thresholds_min <= item.resistance_1k &&
                                  item.resistance_1k <= thresholds.resistance_1k_thresholds_max) ? (
                                      <div style={{ width: '10px', height: '10px', borderRadius: '50%', background: 'green' }}></div>
                                    ) : (
                                      <div style={{ width: '10px', height: '10px', borderRadius: '50%', background: 'red' }}></div>
                                    )
                                ) : null}
                            </td>
                            <td>
                              {item.status === 0 && 'OK'}
                              {item.status === 1 && 'BALANCING'}
                              {item.status === 2 && 'FAULTY'}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table> ) : (
                    <div
                      className="d-flex justify-content-center align-items-center text-center"
                      style={{ height: "150px" }}
                    >
                      <p style={{fontSize: "25px", fontWeight: "bold"}}>No battery for this CCU.</p>
                    </div>
                  )}
                  <Row>
                    <Col
                      md={{ span: 2, offset: 10 }}
                      xs={{ span: 12, offset: 0 }}
                      className="text-center mt-3"
                    >
                      <Button
                        variant="primary btn-animated"
                        className="btn-icon"
                        disabled={currentPage === 1}
                        onClick={() => paginate(currentPage - 1)}
                      >
                        <span>
                          <i className="ri-arrow-left-s-line" />
                        </span>
                      </Button>
                      <span className="mx-1">{currentPage}</span>
                      <Button
                        variant="primary btn-animated "
                        className="btn-icon"
                        disabled={indexOfLastItem >= lists.length}
                        onClick={() => paginate(currentPage + 1)}
                      >
                        <span>
                          <i className="ri-arrow-right-s-line" />
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card.Body>
          </Card>
        </TabContent>
      </Container>
    </>
  )
};

export default BatteryManagement;