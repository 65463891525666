import React, { useEffect } from "react";

import avatar12 from "../../assets/dist/img/avatar12.jpg";
import { CheckSquare, CreditCard, Plus, Settings, Tag } from "react-feather";
import { Button, Dropdown, Nav } from "react-bootstrap";
import { useAuth } from "../../services/authContext";

import { Link } from "react-router-dom";
// import CustomInput from './CustomInput';
const UserAccount = () => {
  const { authenticated, user, logout } = useAuth();

  return authenticated ? (
    <Nav.Item>
      <Dropdown className="ps-2">
        <Dropdown.Toggle as={Link} to="#" className="no-caret">
          <Button>{user.name}</Button>
          {/* <div className="avatar avatar-rounded avatar-xs">
        <img src={avatar12} alt="user" className="avatar-img" />
      </div> */}
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <div className="p-2">
            <div className="media">
              {/* <div className="media-head me-2">
            <div className="avatar avatar-primary avatar-sm avatar-rounded">
              <span className="initial-wrap">Hk</span>
            </div>
          </div> */}
              <div className="media-body">
                <Dropdown>
                  {/* <Dropdown.Toggle
                as={Link}
                to="#"
                className="d-block fw-medium text-dark"
              > */}
                  {/* <div className="d-block fw-medium text-dark">{user.name}</div> */}

                  {/* </Dropdown.Toggle> */}
                  <Dropdown.Menu align="end">
                    <div className="p-2">
                      <Button
                        variant="outline-light"
                        size="sm"
                        className="btn-block"
                      >
                        <span>
                          <span className="icon">
                            <span className="feather-icon">
                              <Plus />
                            </span>
                          </span>
                          <span>Add Account</span>
                        </span>
                      </Button>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <div className="fs-7">{user.email}</div>
                <Link
                  to="#"
                  className="d-block fs-8 link-secondary"
                  onClick={logout}
                >
                  <u>Sign Out</u>
                </Link>
              </div>
            </div>
          </div>
          <Dropdown.Divider as="div" />
          <Dropdown.Item as={Link} to="/pages/profile">
            Profile
          </Dropdown.Item>

          <Dropdown.Divider as="div" />
          {/* <h6 className="dropdown-header">Manage Account</h6> */}

          <Dropdown.Item>
            <span className="dropdown-icon feather-icon">
              <Settings />
            </span>
            <span>Settings</span>
          </Dropdown.Item>
          {/* <Dropdown.Divider as="div" /> */}

          {/* <Dropdown.Divider as="div" /> */}
          {/* <Dropdown.Item>Terms &amp; Conditions</Dropdown.Item>
      <Dropdown.Item>Help &amp; Support</Dropdown.Item> */}
        </Dropdown.Menu>
      </Dropdown>
    </Nav.Item>
  ) : null;
};

export default UserAccount;
