import React from "react";
import {
  Col,
  Container,
  Row,
} from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";

//Images
import image from "../../../assets/dist/img/About.png";

const Body = () => {
  const OverlayComponent = () => (
    <div style={{ position: "absolute", top: "20%", left: "50%", transform: "translate(-50%, -50%)", zIndex: "2", backgroundColor: "rgba(255, 255, 255, 0.75)", padding: "10px", borderRadius: "10px", boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", maxWidth: "45%", height: "115px", textAlign: "center" }}>
      <div style={{ color: "#333", fontSize: "60px", marginBottom: "20px", fontFamily: "initial", fontWeight: "bold" }}>ABOUT US</div>
    </div>
  );

  return (
    <>
      <Container>
        <Row style={{ marginTop: "20px", backgroundColor: "#f9f9f9", padding: "40px", borderRadius: "10px", boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" }}>
          <OverlayComponent />
          <Col md={3}>
            <img src={image} alt="Company Logo" style={{ maxWidth: "100%", borderRadius: "15px", marginTop: "190px" }} />
          </Col>
          <Col md={1} />
          <Col md={8} style={{ paddingLeft: "40px", }}>
            <p style={{ color: "#666", fontSize: "16px", lineHeight: "1.6", marginTop: "160px" }}>
              <strong>WENS Precisions Co. Ltd.</strong> is a Korea-based company with over 20
            years of diversified test and measurement experience including
            General Purpose, Automotive and Telecommunication Test Instrument.
            The long history and vast experience has given WENS a unique
            position as a long term market leader in advanced Test &
            Measurement technologies.
            </p><br />
            <p style={{ color: "#666", fontSize: "16px", lineHeight: "1.6" }}>
            As your partner, WENS will provide you with a complete assortment
            of test products tailored to your specific needs. Innovative
            technology and creative engineering give WENS products the edge
            when it comes to size, weight, ease-of-use and rugged design.
            </p><br />
            <p style={{ color: "#666", fontSize: "16px", lineHeight: "1.6" }}>
            Beginning at the secondary battery era, backed by accumulated
            technology and experience from Testing & Measurement, WENS extends
            to new businesses including Battery Quality Analysis solution and
            Battery Management System.
            </p><br />
            <p style={{ color: "#666", fontSize: "16px", lineHeight: "1.6" }}>
            WENS new unique, future-driven products will build a new character
            in the global market.
            </p><br />
            <p style={{ color: "#666", fontSize: "16px", lineHeight: "1.6" }}>
            For more information, please visit&nbsp;
              <a href="http://www.wens.co.kr/home_eng/sub2_1.html" style={{ color: "#007bff" }}>
              http://www.wens.co.kr/home_eng/sub2_1.html
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Body;
