import React, { useState } from "react";
import classNames from "classnames";
import Body from "./Body";

const IntegrationsDetail = () => {
  const [showSidebar, setShowSidebar] = useState(true);

  return (
    <div className="hk-pg-body py-0">
      <div>
        <div>
          <div>
            <Body />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntegrationsDetail;
