import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import CommanFooter1 from "../../../layout/Footer/PageFooter";
import useAuthServices from "../../../services/authService";
import useUserServices from "../../../services/userService";
import { ToastContainer } from "react-toastify";
import Error410 from "../Error410/Error410";

//image
import logo from "../../../assets/dist/img/Batteye.png";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { jwtDecode } from "jwt-decode"; // Import jwt_decode

const EditPassword = () => {
  const [newPassword, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [tokenExpired, setTokenExpired] = useState(false);
  const [requestedUser, setRequestedUser] = useState("");
  const {
    resetPassword,
    // success,
    // clearSuccess,
    isSubmitting,
    resetIsSubmitting,
    // clearIsSubmitting,
  } = useAuthServices();

  // const { getUserAccount } = useUserServices();
  const { resetToken } = useParams();
  const history = useHistory();

  useEffect(() => {
    resetTokenExpired();
  }, []);

  const resetForm = () => {
    setPassword("");
    setConfirmPassword("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const successReset = await resetPassword(
      newPassword,
      confirmPassword,
      resetToken
    );

    resetForm();
    resetIsSubmitting();

    if (successReset) {
      history.push("/auth/login");
    }
  };

  // useEffect(() => {
  //   if (resetTokenExpired()){
  //     setTokenExpired(true)
  //   }

  //   // Redirect to login page upon successful password reset
  //   if (success) {
  //     resetForm();
  //     history.push("/auth/login");
  //   }

  //   // clearIsSubmitting();
  //   // clearSuccess();
  // }, [success]);

  const resetTokenExpired = async () => {
    try {
      const decodedToken = jwtDecode(resetToken);
      setRequestedUser(decodedToken.userId)
      // const authUser = await getUserAccount(decodedToken.userId);

      // setRequestedUser(authUser)

      const currentTime = Date.now() / 1000; // Convert milliseconds to seconds

      if (decodedToken.exp < currentTime) {
        setTokenExpired(true)
        return true; // Token is expired
      } else {
        setTokenExpired(false)
        return false; // Token is not expired
      }
    } catch (error) {
      console.log("Error decoding token:", error);
      return true; // Error decoding token, consider it expired
    }
  };
  return (
    <div className="hk-pg-wrapper pt-0 pb-xl-0 pb-5">
      <div>
        <Container>
          <Row>
            <Col sm={10} className="position-relative mx-auto">
              <div className="auth-content py-8">
                {tokenExpired ? (
                  <Error410 />
                ) : requestedUser ? (
                  requestedUser?.reset_password_token &&
                  requestedUser?.reset_password_token === resetToken ? (
                    <Error410 />
                  ) : (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Col lg={5} md={7} sm={10} className="mx-auto">
                          <div className="text-center mb-7">
                            {/* <Link to="/" className="navbar-brand me-0"> */}
                              <img
                                className="brand-img d-inline-block"
                                src={logo}
                                alt="brand"
                              />
                            {/* </Link> */}
                          </div>
                          <Card className="card-flush">
                            <Card.Body className="text-center">
                              <h4>Reset New Password</h4>
                              <Row className="gx-3">
                                <Col lg={12} as={Form.Group} className="mb-3">
                                  <div className="form-label-group">
                                    <Form.Label htmlFor="userName">
                                      New Password
                                    </Form.Label>
                                  </div>
                                  <Form.Control
                                    placeholder="New Password"
                                    type="password"
                                    value={newPassword}
                                    onChange={(e) => {
                                      setPassword(e.target.value);
                                    }}
                                    id="newPassword"
                                  />
                                  <br />
                                  <div className="form-label-group">
                                    <Form.Label htmlFor="userName">
                                      Confirm Password
                                    </Form.Label>
                                  </div>

                                  <Form.Control
                                    placeholder="Confirm Password"
                                    type="password"
                                    value={confirmPassword}
                                    onChange={(e) => {
                                      setConfirmPassword(e.target.value);
                                    }}
                                    id="confirmPassword"
                                  />
                                </Col>
                              </Row>
                              <Button
                                type="submit"
                                variant="primary"
                                className="btn-uppercase btn-block"
                                disabled={isSubmitting} // Disable the button when success is true
                                id="resetBtn"
                              >
                                {isSubmitting
                                  ? "Reseting Password..."
                                  : "Set New Password"}
                              </Button>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </Form>
                  )
                ) : (
                  <div className="text-center py-50">
                    <Spinner />{" "}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
        <ToastContainer
          position="top-right"
          style={{ top: "70px", right: "20px" }}
          enableMultiContainer
          containerId="toast-container"
          autoClose={800}
        />
      </div>
      {/* Page Footer */}
      {/* <CommanFooter1 /> */}
    </div>
  );
};

export default EditPassword;
