
import { useState } from "react";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;

const useAuthServices = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)



  const userLogin = async (email, password) => {
    try {
      const response = await fetch(`${backendUri}/api/v0/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        Cookies.set("BMStoken", data.token);
        return { success: true, data }; // Return true for successful login
      } else {
        return { success: false, data }; // Return false for failed login
      }
    } catch (error) {
      console.error("Fetch API fail", error);
      return { success: false, data: null }; // Return false for any errors during login
    }
  };

  const resetPassword = async (newPassword, confirmPassword, token) => {

    try {
      setIsSubmitting(true)
      const response = await fetch(`${backendUri}/api/v0/auth/reset-password/${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ newPassword, confirmPassword }),
      });

      const data = await response.json();

      if (response.ok) {
        toast.success(data.message, {
          containerId: "toast-container",
        });
        return true; // Return true for successful login
      } else {
        toast.error(data.message, {
          containerId: "toast-container",
        });
        return false; // Return false for failed login
      }
    } catch (error) {
      console.error("Fetch API fail", error);
      return false; // Return false for any errors during login
    }
  };

  const resetIsSubmitting = () =>{
    setIsSubmitting(false)
  }



  const sendResetPasswordLink = async (email) => {
    try {
      const response = await fetch(`${backendUri}/api/v0/auth/forgot-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        toast.success(data.message, {
          containerId: "toast-container",
        });
        return true; // Return true for successful login
      } else {
        toast.error(data.message, {
          containerId: "toast-container",
        });
        return false; // Return false for failed login
      }
    } catch (error) {
      console.error("Fetch API fail", error);
      return false; // Return false for any errors during login
    }
  };

  return {
    userLogin,
    sendResetPasswordLink,
    resetPassword,
    resetIsSubmitting,
    isSubmitting
  };
};

export default useAuthServices;
