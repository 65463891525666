import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Form,
  InputGroup,
  Nav,
  Dropdown,
  Button,
  Tab,
} from "react-bootstrap";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import Cookies from "js-cookie";

import { connect } from "react-redux";
import { toggleCollapsedNav } from "../../redux/action/Theme";
import useCcuServices from "../../services/ccuService";
import useBatteryServices from "../../services/batteryService";
import GraphDisplay from "./GraphDisplay";
import "chartjs-adapter-moment";
import DataExport from "./DataExport";

const Dashboard = ({ navCollapsed, toggleCollapsedNav }) => {
  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  //Allow set filtered data
  const [allowFilterBatteryLogs, setAllowFilterBatteryLogs] = useState(false)

  //Services Imported
  const { getAllCcus, getAllCcuLogs } = useCcuServices();
  const { getAllBatteriesLogsByCcu, getAllBatteriesByCcu } =
    useBatteryServices();

  //To store company ccus
  const [companyCcus, setCompanyCcus] = useState([]);

  //To store the ccu and batteries log accordingly to the id
  const [ccuBatteries, setCcuBatteries] = useState({});
  const [ccuData, setCcuData] = useState({});

  //To store the batteries id of the selected ccu
  const [batteriesIdByCcu, setBatteriesIdCcu] = useState([]);

  //Store only filtered ccu and battery logs for graph display
  const [filteredBatteryLogs, setFilteredBatteryLogs] = useState({});
  const [filteredCcusDataLogs, setFilteredCcusDataLogs] = useState({});

  //To store the selected ccu for batteries graph display
  const [selectedCcu, setSelectedCcu] = useState(
    Cookies.get("BMSSelectedCCU") ? Cookies.get("BMSSelectedCCU") : ""
  );

  //To store start date and end date
  const [startDate, setStartDate] = useState(
    Cookies.get("BMSStartDate")
      ? parseInt(Cookies.get("BMSStartDate"))
      : new Date().getTime()
  );
  const [endDate, setEndDate] = useState(
    Cookies.get("BMSEndDate")
      ? parseInt(Cookies.get("BMSEndDate"))
      : tomorrow.getTime()
  );

  //To store selected batteries and selected ccus
  const [selectedBatteries, setSelectedBatteries] = useState(
    Cookies.get("BMSSelectedBatteries")
      ? JSON.parse(Cookies.get("BMSSelectedBatteries"))
      : []
  );
  const [selectedCcus, setSelectedCcus] = useState(
    Cookies.get("BMSSelectedCCUs")
      ? JSON.parse(Cookies.get("BMSSelectedCCUs"))
      : []
  );

  //Chart container
  const voltageChartContainer = useRef(null);
  const temperatureChartContainer = useRef(null);
  const resistanceChartContainer = useRef(null);
  const ccuCurrentChartContainer = useRef(null);

  // Inside your functional component
  const startDateRef = useRef(startDate);
  const endDateRef = useRef(endDate);
  const ccuIdRef = useRef(selectedCcu);

  //CCU selection for displaying battery
  const selectCcu = (ccu_id) => {
    setSelectedCcu(ccu_id);
    ccuIdRef.current = ccu_id;
    // Cookies.set("BMSSelectedCCU", ccu_id);
  };

  //Start date and end date selection
  const selectedStartDate = (startDate) => {
    startDateRef.current = new Date(startDate).getTime();
    setStartDate(new Date(startDate).getTime());
    Cookies.set("BMSStartDate", new Date(startDate).getTime());
  };

  const selectedEndDate = (endDate) => {
    let actualEndDate = new Date(endDate);
    actualEndDate.setDate(actualEndDate.getDate() + 1);
    endDateRef.current = actualEndDate.getTime();
    setEndDate(actualEndDate.getTime());
    Cookies.set("BMSEndDate", new Date(actualEndDate).getTime());
  };

  //Date range reseting
  const resetStartEndDate = () => {
    setStartDate("");
    setEndDate("");
  };

  //Fetch all company ccus and set company ccus for graph display and data export
  const fetchCcus = async () => {
    const ccus = await getAllCcus();
    if (ccus.success) {
      if (Array.isArray(ccus.data)) {
        setCompanyCcus(ccus.data);
      }
    }
  };

  //Fetching all the batteries with the ccu select and get all the batteries data
  const fetchBatteriesByCcu = async (ccu_id) => {
    const batteriesId = await getAllBatteriesByCcu(ccu_id);
    let batteries = [];
    if (batteriesId.success) {
      if (Array.isArray(batteriesId.data)) {
        batteriesId.data.forEach((battery) => {
          batteries.push(battery.battery_id);
        });

        if (ccu_id !== Cookies.get("BMSSelectedCCU")) {
          //For graph display
          setSelectedBatteries(batteries);
        }
      }

      //For drop down selection purpose
      setBatteriesIdCcu(batteries);
    }
    Cookies.set("BMSSelectedCCU", ccu_id);
    await fetchBatteriesLog(ccu_id);
  };

  //Fetch the batteries log according to the ccu selected
  const fetchBatteriesLog = async (ccu_id) => {
    const batteriesLogs = await getAllBatteriesLogsByCcu(
      ccu_id,
      startDateRef.current,
      endDateRef.current
    );
    if (batteriesLogs.success) {
      setCcuBatteries(batteriesLogs.data);
      setAllowFilterBatteryLogs(true)
    }
  };

  //Function to fetch the ccus log within the date range selected
  const fetchCcuData = async () => {
    const ccuLogs = await getAllCcuLogs(
      startDateRef.current,
      endDateRef.current
    );
    if (ccuLogs.success) {
      setCcuData(ccuLogs.data);
    }
  };

  //Select all ccus
  const selectAllCcus = () => {
    let companyCcusId = [];
    if (Array.isArray(companyCcus)) {
      companyCcus.forEach((ccu) => {
        companyCcusId.push(ccu.ccu_id);
      });
    }
    if((Cookies.get("BMSSelectedCCUs") && JSON.parse(Cookies.get("BMSSelectedCCUs")).length <= 0) || !Cookies.get("BMSSelectedCCUs")){
      setSelectedCcus(companyCcusId);
    }
    
  };

  //Unselect all ccus
  const unSelectAllCcus = () => {
    setSelectedCcus([]);
  };

  //Select all batteries under a ccu
  const selectAllBatteries = () => {
    setSelectedBatteries(batteriesIdByCcu);
    setAllowFilterBatteryLogs(true)
  };

  //Unselect all batteries under a ccu
  const unselectAllBatteries = () => {
    setSelectedBatteries([]);
    setAllowFilterBatteryLogs(true)
  };



  //Managing the selection of the batteries based on the checked or unchecked condition of the batteries drop down
  const handleSelectBatteries = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedBatteries([...selectedBatteries, parseInt(value)]);
    } else {
      setSelectedBatteries(
        selectedBatteries.filter((item) => parseInt(item) !== parseInt(value))
      );
    }
    setAllowFilterBatteryLogs(true)
  };

  //Managing the selection of the ccus based on the checked or unchecked condition of the ccus drop down
  const handleSelectCcus = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedCcus([...selectedCcus, value]);
    } else {
      setSelectedCcus(selectedCcus.filter((item) => item !== value));
    }
  };

  //At initial, fetch all the company ccus and also fetch the ccu log data
  useEffect(() => {
    fetchCcus(); // fetch all company ccus
    toggleCollapsedNav(false);
    fetchCcuData(); // fetch all the company ccus logs within the date range

    const interval = setInterval(
      async () => {
        await fetchCcuData();
        if (ccuIdRef.current) {
          await fetchBatteriesLog(ccuIdRef.current);
        }
      },
      // 5 * 60 * 1000
      5000
    ); // Refresh every 5 minutes
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //If ccu is selected or changes, fetch the ccu's batteries
  useEffect(() => {
    if (selectedCcu) {
      fetchBatteriesByCcu(selectedCcu);
    }
  }, [selectedCcu]);

  //To filter out the batteries log object based on the battery selected only
  useEffect(() => {
    if(allowFilterBatteryLogs){
      let filteredBatteriesData = {};

      // Filter the ccuBatteries log object based on selectedItems
      Object.entries(ccuBatteries).forEach(([key, value]) => {
        if (selectedBatteries.includes(parseInt(key))) {
          filteredBatteriesData[key] = value; // Add the matched data to the filteredBatteriesData object
        }
      });
  
      setFilteredBatteryLogs(filteredBatteriesData);
      Cookies.set("BMSSelectedBatteries", JSON.stringify(selectedBatteries));
    }
    setAllowFilterBatteryLogs(false)

  }, [allowFilterBatteryLogs]);

  //When the company ccus had been set, select all the company ccus
  useEffect(() => {
    selectAllCcus();
  }, [companyCcus]);

  //To filter out the ccus log object based on the ccus selected only
  useEffect(() => {
    let filteredCcusData = {};
    Object.entries(ccuData).forEach(([key, value]) => {
      if (selectedCcus.includes(key)) {
        filteredCcusData[key] = value; // Add the matched data to the filteredBatteriesData object
      }
    });

    setFilteredCcusDataLogs(filteredCcusData);
    Cookies.set("BMSSelectedCCUs", JSON.stringify(selectedCcus));
  }, [selectedCcus, ccuData]);

  //If there is selection of start date and end date, fetch all the ccu data logs according to the date range
  useEffect(() => {
    if (startDate && endDate) {
      fetchCcuData();
      //If there is ccu is selected, select all the batteries log under the ccu also
      if (selectedCcu) {
        fetchBatteriesByCcu(selectedCcu);
      }
    }
  }, [startDate, endDate]);

  return (
    <>
      <Container>
        <Tab.Container activeKey="overview">
          {/* Page Header */}
          <div className="hk-pg-header pg-header-wth-tab pt-7">
            <div className="d-flex">
              <div className="d-flex flex-wrap justify-content-between flex-1">
                <div>
                  {startDate && endDate ? (
                    `Data Retrieved From:  ${moment(startDate).format(
                      "DD/MM/YYYY"
                    )} ~ ${moment(endDate)
                      .subtract(1, "days")
                      .format("DD/MM/YYYY")}`
                  ) : (
                    <div className="mb-5 pg-header-action-wrap">
                      <div>
                        <InputGroup className="w-200p">
                          <span className="input-affix-wrapper">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control
                              type="date"
                              value={moment(new Date(startDate)).format(
                                "YYYY-MM-DD"
                              )}
                              onChange={(e) => {
                                selectedStartDate(e.target.value);
                              }}
                              // max={moment(new Date(startDate))
                              //   .add(2, "days")
                              //   .format("YYYY-MM-DD")} // Set maximum date to two days ahead of startDate
                            />
                          </span>
                        </InputGroup>
                      </div>

                      <div style={{ marginLeft: "30px" }}>
                        <InputGroup className="w-200p">
                          <span className="input-affix-wrapper">
                            <Form.Label>End Date</Form.Label>
                            <Form.Control
                              disabled={!startDate}
                              type="date"
                              value={moment(new Date(endDate)).format(
                                "YYYY-MM-DD"
                              )}
                              onChange={(e) => {
                                selectedEndDate(e.target.value);
                              }}
                              min={
                                startDate
                                  ? new Date(startDate)
                                      .toISOString()
                                      .split("T")[0]
                                  : null
                              } // Set minimum date to today
                              // max={moment(new Date(startDate))
                              //   .add(2, "days")
                              //   .format("YYYY-MM-DD")} // Set maximum date to two days ahead of startDate
                            />
                          </span>
                        </InputGroup>
                      </div>
                    </div>
                  )}
                </div>
                {/* <Button onClick={resetStartEndDate}>Reset Date Range</Button> */}
              </div>
            </div>
            <Nav variant="tabs" className="nav-light nav-line">
              <Nav.Item style={{ marginLeft: "10px", marginRight: "10px" }}>
                <Button
                  onClick={resetStartEndDate}
                  size="sm"
                  disabled={!startDate}
                >
                  Set Date Range
                </Button>
              </Nav.Item>
              <Nav.Item id="view-system-current">
                <Dropdown>
                  <Dropdown.Toggle as={Nav.Link} id="dropdown-show-items">
                    View System Current
                  </Dropdown.Toggle>
                  {Array.isArray(companyCcus) ? (
                    <Dropdown.Menu style={{ width: "200px" }}>
                      <div
                        style={{
                          marginBottom: "8px",
                          display: "flex",
                          flexDirection: "row",
                          borderBottom: "3px solid black",
                        }}
                      >
                        <div style={{ marginRight: "25px" }}>
                          <span
                            onClick={selectAllCcus}
                            size="sm"
                            style={{ cursor: "pointer", color: "black" }}
                            onMouseEnter={(e) =>
                              (e.target.style.color = "blue")
                            } // Change color on hover
                            onMouseLeave={(e) =>
                              (e.target.style.color = "black")
                            } // Revert color on mouse leave
                          >
                            Select All
                          </span>
                        </div>

                        <div>
                          <span
                            onClick={unSelectAllCcus}
                            size="sm"
                            style={{ cursor: "pointer", color: "black" }}
                            onMouseEnter={(e) =>
                              (e.target.style.color = "blue")
                            } // Change color on hover
                            onMouseLeave={(e) =>
                              (e.target.style.color = "black")
                            } // Revert color on mouse leave
                          >
                            Unselect All
                          </span>
                        </div>
                      </div>
                      <Form>
                        {companyCcus.map((ccu) => (
                          <Form.Check
                            key={`ccu-${ccu.ccu_id}`}
                            type="checkbox"
                            id={`ccu-${ccu.ccu_id}`}
                            label={`${ccu.ccu_id}`}
                            value={ccu.ccu_id}
                            checked={selectedCcus.includes(ccu.ccu_id)} // Set checked dynamically based on state
                            onChange={handleSelectCcus}
                          />
                        ))}
                      </Form>
                    </Dropdown.Menu>
                  ) : null}
                </Dropdown>
              </Nav.Item>
              <Nav.Item>
                <Dropdown as={Nav.Item}>
                  <Dropdown.Toggle as={Nav.Link} id="dropdown-analytics">
                    <span className="nav-link-text" id="selectCCUBtn">
                      {selectedCcu
                        ? `Selected CCU: ${selectedCcu}`
                        : "Select CCU"}
                    </span>
                  </Dropdown.Toggle>
                  {Array.isArray(companyCcus) ? (
                    <Dropdown.Menu>
                      {companyCcus.map((ccu) => (
                        <Dropdown.Item
                          key={ccu.ccu_id}
                          eventKey={ccu.ccu_id}
                          onClick={() => selectCcu(ccu.ccu_id)}
                        >
                          {ccu.ccu_id}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  ) : null}
                </Dropdown>
              </Nav.Item>
              {batteriesIdByCcu.length !== 0 ? (
                <Nav.Item id="select-battery">
                  <Dropdown>
                    <Dropdown.Toggle as={Nav.Link} id="dropdown-show-items">
                      Select Battery
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ width: "200px" }}>
                      <div
                        style={{
                          marginBottom: "8px",
                          display: "flex",
                          flexDirection: "row",
                          borderBottom: "3px solid black",
                        }}
                      >
                        <div style={{ marginRight: "25px" }}>
                          <span
                            onClick={selectAllBatteries}
                            size="sm"
                            style={{ cursor: "pointer", color: "black" }}
                            onMouseEnter={(e) =>
                              (e.target.style.color = "blue")
                            } // Change color on hover
                            onMouseLeave={(e) =>
                              (e.target.style.color = "black")
                            } // Revert color on mouse leave
                          >
                            Select All
                          </span>
                        </div>

                        <div>
                          <span
                            onClick={unselectAllBatteries}
                            size="sm"
                            style={{ cursor: "pointer", color: "black" }}
                            onMouseEnter={(e) =>
                              (e.target.style.color = "blue")
                            } // Change color on hover
                            onMouseLeave={(e) =>
                              (e.target.style.color = "black")
                            } // Revert color on mouse leave
                          >
                            Unselect All
                          </span>
                        </div>
                      </div>

                      <Form>
                        {batteriesIdByCcu.map((battery) => (
                          <Form.Check
                            key={`battery-${battery}`}
                            type="checkbox"
                            id={`battery-${battery}`}
                            label={`Battery ${battery}`}
                            value={battery}
                            checked={selectedBatteries.includes(battery)} // Set checked dynamically based on state
                            onChange={handleSelectBatteries}
                          />
                        ))}
                      </Form>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav.Item>
              ) : null}

              <DataExport
                selectedCcu={selectedCcu}
                startDate={startDate}
                endDate={endDate}
                filteredCcusDataLogs={filteredCcusDataLogs}
                filteredBatteryLogs={filteredBatteryLogs}
                ccuCurrentChartContainer={ccuCurrentChartContainer}
                voltageChartContainer={voltageChartContainer}
                temperatureChartContainer={temperatureChartContainer}
                resistanceChartContainer={resistanceChartContainer}
              />
            </Nav>
          </div>
          {/* /Page Header */}
          {/* Page Body */}
          <GraphDisplay
            selectedCcu={selectedCcu}
            ccuBatteries={filteredBatteryLogs}
            ccuData={filteredCcusDataLogs}
            voltageChartContainer={voltageChartContainer}
            temperatureChartContainer={temperatureChartContainer}
            resistanceChartContainer={resistanceChartContainer}
            ccuCurrentChartContainer={ccuCurrentChartContainer}
            // selectedCcus={selectedCcus}
            // selectedBatteries={selectedBatteries}
          />
          {/* /Page Body */}
        </Tab.Container>
      </Container>
    </>
  );
};

// export default Dashboard
const mapStateToProps = ({ theme }) => {
  const { navCollapsed } = theme;
  return { navCollapsed };
};

export default connect(mapStateToProps, { toggleCollapsedNav })(Dashboard);
