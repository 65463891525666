import Dashboard from "../views/Dashboard";
import Login from "../views/Authentication/LogIn/Login/Login";
import LockScreen from "../views/Authentication/LockScreen";
import ResetPassword from "../views/Authentication/ResetPassword";
import EditPassword from "../views/Authentication/ResetPassword/EditPassword";
import Error404 from "../views/Authentication/Error404/Error404";
import Error503 from "../views/Authentication/Error503/Error503";
import AdminManagement from "../views/AdminManagement";
import CompanyManagement from "../views/CompanyManagement";
import CCUManagement from "../views/CCUManagement";
import BatteryManagement from "../views/BatteryManagement";
import CCUWhiteList from "../views/CCUWhiteList";
import IntegrationsDetail from "../views/Integrations/AboutUs";

export const routes = [
  { path: "error-404", exact: true, component: Error404 },
];

export const authenticatedRoutes = [
  { path: "/dashboard", exact: true, component: Dashboard, roles: [1, 2, 3] },
  {
    path: "/admin",
    exact: true,
    component: AdminManagement,
    roles: [1] 
  },
  { path: "/company", exact: true, component: CompanyManagement },
  { path: "/CCU", exact: true, component: CCUManagement },
  { path: "/battery/:id", exact: true, component: BatteryManagement },
  { path: "/white-list", exact: true, component: CCUWhiteList },
  { path: "/about-us", exact: true, component: IntegrationsDetail },
];

export const authRoutes = [
  { path: "/login", exact: true, component: Login },
  { path: "/lock-screen", exact: true, component: LockScreen },
  { path: "/reset-password", exact: true, component: ResetPassword },
  {
    path: "/edit-password/:resetToken",
    exact: true,
    component: EditPassword,
  },
  { path: "/error-503", exact: true, component: Error503 },
];
