import { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  TabContent,
  Table,
  Form,
  InputGroup,
} from "react-bootstrap";
import { Plus } from "react-feather";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import CreateModal from "./CreateModal";
import EditModal from "./EditModal";
import DeleteModal from "./DeleteModal";

const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;

const CCUWhiteList = () => {
  const [Token, setToken] = useState(Cookies.get("BMStoken"));

  const [lists, setList] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredList, setFilteredLists] = useState([...lists]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = filteredList.slice(indexOfFirstItem, indexOfLastItem);

  const [CCUId, setCCUId] = useState("");

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [showCreateModal, setShowCreateModal] = useState(false);
  const handleCreateOpen = () => {
    setShowCreateModal(true);
  };
  const handleCreateClose = () => {
    setShowCreateModal(false);
  };

  const [showEditModal, setShowEditModal] = useState(false);
  const handleEditOpen = (id) => {
    setCCUId(id);
    setShowEditModal(true);
  };
  const handleEditClose = () => {
    setShowEditModal(false);
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteOpen = (id) => {
    setCCUId(id);
    setShowDeleteModal(true);
  };
  const handleDeleteClose = () => {
    setShowDeleteModal(false);
  };

  useEffect(() => {
    if (searchTerm) {
      const filterItems = lists.filter(
        (item) =>
          item.ccu_id.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredLists(filterItems);
    } else {
      setFilteredLists([...lists]);
    }
  }, [lists, searchTerm]);

  const fetchData = async (Token) => {
    await fetch(`${backendUri}/api/v0/admin/ccus`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data && data.length > 0) {
          setList(data);
        } else {
          setList([]);
        }
      })
      .catch((err) => {
        console.error("Login Failed due to: " + err.message);
      });
  };

  useEffect(() => {
    fetchData(Token);
  }, []);

  return (
    <>
      <Container>
        <TabContent>
          <Card
            className="card-border mb-0 h-100"
          >
            <Card.Header className="card-header-action">
              <h5>CCU White List Management</h5>
              <Button
                variant="primary btn-animated"
                size="sm"
                className="ms-3"
                id="createCCUBtn"
                onClick={handleCreateOpen}
              >
                <span>
                  <span className="icon">
                    <span className="feather-icon">
                      <Plus />
                    </span>
                  </span>
                  <span className="bn-text" size="lg">
                    Create CCU
                  </span>
                </span>
              </Button>
            </Card.Header>
            <Card.Body>
              <InputGroup className="mb-4">
                <Form.Control 
                  placeholder="Search..."
                  className="table-search"
                  type="text"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </InputGroup>
              <div className="invoice-list-view">
                <div className="table-responsive">
                  <Table hover className="mb-0">
                    <thead>
                      <tr>
                        <th>CCU ID</th>
                        <th>Company Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody id="dataTable">
                      {currentItems.map((item) => (
                        <tr key={item.id}>
                          <td>{item.ccu_id}</td>
                          <td>{item.company_name}</td>
                          <td>
                            <Button
                              id="editBtn"
                              size="sm"
                              variant="soft-primary"
                              style={{ margin: 5 }}
                              className="btn-rounded"
                              onClick={() => {
                                handleEditOpen(item.ccu_id);
                              }}
                            >
                              Edit
                            </Button>
                            <Button
                              id="deleteBtn"
                              size="sm"
                              variant="soft-danger"
                              style={{ margin: 5 }}
                              className="btn-rounded"
                              onClick={() => {
                                handleDeleteOpen(item.ccu_id);
                              }}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Row>
                    <Col
                      md={{ span: 2, offset: 10 }}
                      xs={{ span: 12, offset: 0 }}
                      className="text-center mt-3"
                    >
                      <Button
                        variant="primary btn-animated"
                        className="btn-icon"
                        disabled={currentPage === 1}
                        onClick={() => paginate(currentPage - 1)}
                      >
                        <span>
                          <i className="ri-arrow-left-s-line" />
                        </span>
                      </Button>
                      <span className="mx-1">{currentPage}</span>
                      <Button
                        variant="primary btn-animated "
                        className="btn-icon"
                        disabled={indexOfLastItem >= lists.length}
                        onClick={() => paginate(currentPage + 1)}
                      >
                        <span>
                          <i className="ri-arrow-right-s-line" />
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card.Body>
          </Card>
        </TabContent>
      </Container>
      <ToastContainer
        position="top-right"
        style={{ top: "70px", right: "20px" }}
        enableMultiContainer
        containerId="error-container"
      />
      <ToastContainer
        position="top-right"
        style={{ top: "70px", right: "20px" }}
        enableMultiContainer
        containerId="success-container"
      />
      <CreateModal
        showModal={showCreateModal}
        closeModal={handleCreateClose}
        Token={Token}
        fetchData={fetchData}
      />
      <EditModal
        showModal={showEditModal}
        closeModal={handleEditClose}
        Token={Token}
        fetchData={fetchData}
        CCUId={CCUId}
      />
      <DeleteModal
        showModal={showDeleteModal}
        closeModal={handleDeleteClose}
        Token={Token}
        fetchData={fetchData}
        CCUId={CCUId}
      />
    </>
  )
};

export default CCUWhiteList;