import { useState, useEffect } from "react";
import { Modal, Button, Form, Row, InputGroup, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { Country, State } from "country-state-city";
import "react-toastify/dist/ReactToastify.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faCity, faEnvelope, faGlobe, faMap } from '@fortawesome/free-solid-svg-icons';

const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;

const EditCompanyModal = (props) => {
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [fax, setFax] = useState("");
  const [companyLogo, setCompanyLogo] = useState(null);
  const [fileName, setFileName] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [file, setFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [countries, setAllCountries] = useState([]);
  const [states, setAllStates] = useState([]);

  useEffect(() => {
    fetchCompanyData(props.companyId);
  }, [props.showForm]);

  const handleCountryChange = (event) => {
    const newCountry = event.target.value;
    setCountry(newCountry);
  };

  const handleStateChange = (event) => {
    const newState = event.target.value;
    setState(newState);
  };

  const fetchCompanyData = async (id) => {
    try {
      const response = await fetch(
        `${backendUri}/api/v0/admin/companies/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${props.Token}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setName(data.name || "");
        setContact(data.contact || "");
        setEmail(data.email || "");
        setFax(data.fax || "");
        setCompanyLogo(data.logo || null);
        setFileName(data.logo || "");
        setAddressLine1(data.address_line_1 || "");
        setAddressLine2(data.address_line_2 || "");
        setZipCode(data.zip_code || "");
        setState(data.state || "");
        setCity(data.city || "");
        setCountry(data.country || "");
        setFile(0);
      } else {
        console.error("Fail to fetch company data");
      }
    } catch (error) {
      console.error("Error fetching company data.")
    }
  };

  useEffect(() => {
    const countries = Country.getAllCountries();
    setAllCountries(countries);
    if (!props.restaurant) {
      const malaysia = countries.find((country) => country.name === "Malaysia");
      setCountry(malaysia.isoCode);
    }
  }, []);

  useEffect(() => {
    if (country) {
      const statesForCountry = State.getStatesOfCountry(country);
      setAllStates(statesForCountry);
      if (!props.restaurant) {
        setState("");
      }
    }
  }, [country]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("name", name);
    formData.append("contact", contact);
    formData.append("email", email);
    formData.append("fax", fax);
    formData.append("address_line_1", addressLine1);
    formData.append("address_line_2", addressLine2);
    formData.append("zip_code", zipCode);
    formData.append("city", city);
    formData.append("state", state);
    formData.append("country", country);
    formData.append("file", file);
    formData.append("companyLogo", companyLogo);

    let isTimedOut = false;

    const timeout = setTimeout(() => {
      isTimedOut = true;
      setIsSubmitting(false);
      toast.error("Request timed out. Please try again later.", {
        containerId: "error-container",
      });
      props.handleCloseForm();
    }, 5000);

    fetch(`${backendUri}/api/v0/admin/companies/${props.companyId}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${props.Token}`,
      },
      body: formData
    })
      .then((res) => {
        clearTimeout(timeout);
        return res.json();
      })
      .then((res) => {
        console.log(res.error);
        if (res.error) {
          toast.error(res.message, {
            containerId: "error-container",
          });
          props.handleCloseForm();
        } else {
          toast.success(res.message, {
            containerId: "success-container",
          });
          props.fetchData(props.Token);
          props.handleCloseForm();
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Modal show={props.showForm} onHide={props.handleCloseForm} size="lg">
        <Modal.Header closeButton>
          <Modal.Title> Edit Company </Modal.Title>
        </Modal.Header>
        <Form style={{ margin:3}} onSubmit={(e) => handleUpdate(e)}>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group controlId="editCompanyName">
                <Form.Label> Company Name* </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="fa fa-tag" />
                  </InputGroup.Text>
                  <Form.Control 
                    required
                    type="text"
                    placeholder="Enter Company Name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group controlId="editCompanyContact">
                <Form.Label> Contact* </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="fa fa-phone" />
                  </InputGroup.Text>
                  <Form.Control 
                    required
                    type="text"
                    placeholder="Enter Contact"
                    value={contact}
                    onChange={(e) => {
                      setContact(e.target.value);
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group controlId="editCompanyEmail">
                <Form.Label> Email* </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="fa fa-inbox" />
                  </InputGroup.Text>
                  <Form.Control 
                    required
                    type="text"
                    placeholder="Enter Email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group controlId="editCompanyFax">
                <Form.Label> Fax </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="fa fa-fax" />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Enter Fax"
                    value={fax}
                    onChange={(e) => {
                      setFax(e.target.value);
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group controlId="editCompanyAddressLine1">
                <Form.Label> Address Line 1* </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                  </InputGroup.Text>
                  <Form.Control 
                    required
                    type="text"
                    placeholder="Enter Address Line 1"
                    value={addressLine1}
                    onChange={(e) => {
                      setAddressLine1(e.target.value);
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group controlId="editCompanyAddressLine2">
                <Form.Label> Address Line 2* </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                  </InputGroup.Text>
                  <Form.Control 
                    required
                    type="text"
                    placeholder="Enter Address Line 2"
                    value={addressLine2}
                    onChange={(e) => {
                      setAddressLine2(e.target.value);
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="editCompanyCity">
                <Form.Label> City* </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faCity} />
                  </InputGroup.Text>
                  <Form.Control 
                    required
                    type="text"
                    placeholder="Enter City"
                    value={city}
                    onChange={(e) => {
                      setCity(e.target.value);
                    }}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} controlId="editCompanyZipCode">
                <Form.Label> Zip Code* </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </InputGroup.Text>
                  <Form.Control 
                    required
                    type="number"
                    placeholder="Enter Zip Code"
                    value={zipCode}
                    onChange={(e) => {
                      setZipCode(e.target.value);
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="editCompanyCountry">
                <Form.Label>Country*</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faGlobe} />
                  </InputGroup.Text>
                  <Form.Select
                    name="country"
                    onChange={handleCountryChange}
                    value={country}
                    required
                  >
                    <option value="">Choose...</option>
                    {countries.map((country) => (
                      <option key={country.isoCode} value={country.isoCode}>
                        {country.name}
                      </option>
                    ))}
                  </Form.Select>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} controlId="editCompanyState">
                <Form.Label>State*</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faMap} />
                  </InputGroup.Text>
                  <Form.Select
                    name="state"
                    onChange={handleStateChange}
                    value={state}
                    required
                  >
                    <option value="">Choose...</option>
                    {states.map((state) => (
                      <option key={state.isoCode} value={state.isoCode}>
                        {state.name}
                      </option>
                    ))}
                  </Form.Select>
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group controlId="editCompanyLogo">
                <Form.Label>Company Logo</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="fa fa-image" />
                  </InputGroup.Text>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    name="logo"
                    onChange={(e) => {
                      if (e.target.files.length > 0) {
                        setFile(1);
                        setCompanyLogo(e.target.files[0]);
                      } else {
                        setFile(0);
                      }
                    }}
                  />
                </InputGroup>
                <span id="uploadedFile">
                    Uploaded File: {fileName && typeof fileName === 'string'
                    ? fileName.split('/').pop()
                    : 'No file uploaded'}
                </span>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group controlId="previewCompanyLogo">
                <Form.Label> Preview Uploaded Logo: </Form.Label>
                <div className="d-flex flex-column">
                  {fileName ? (
                    <img
                      src={`${fileName}?timestamp=${Date.now()}`}
                      alt="Preview"
                      className="preview-image img-fluid"
                      style={{
                        maxWidth: '50%', height: 'auto',
                      }}
                    />
                  ) : (
                    <span className="ms-3">
                      No file uploaded
                    </span>
                  )}
                </div>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="soft-primary"
              id="editSubmitBtn"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span className="visually-hidden">Submitting...</span>
                </>
              ) : (
                "Update"
              )}
            </Button>
            <Button
              variant="soft-secondary"
              onClick={props.handleCloseForm}
              id="editCloseBtn"
            >
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
};

export default EditCompanyModal;