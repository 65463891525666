import Cookies from "js-cookie";
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;

const useBatteryServices = () => {
  const getAllBatteriesLogsByCcu = async (ccu_id, startDate, endDate) => {
    try {
      const response = await fetch(`${backendUri}/api/v0/company/battery-log/${ccu_id}?startDate=${startDate}&endDate=${endDate}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("BMStoken")}`,
        },
      });

      const data = await response.json();
      if (response.ok) {
        return { success: true, data };
      } else {
        return { success: false, data };
      }
    } catch (error) {
      console.error("Fetch API fail", error);
      return { success: false, data: null }; // Return false for any errors during login
    }
  };

  const getAllBatteriesByCcu = async (ccu_id) => {
    try {
      const response = await fetch(`${backendUri}/api/v0/company/battery/${ccu_id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("BMStoken")}`,
        },
      });

      const data = await response.json();
      if (response.ok) {
        return { success: true, data };
      } else {
        return { success: false, data };
      }
    } catch (error) {
      console.error("Fetch API fail", error);
      return { success: false, data: null }; // Return false for any errors during login
    }
  };


  return {
    getAllBatteriesLogsByCcu,
    getAllBatteriesByCcu,
  }
};

export default useBatteryServices;