import * as Icons from "tabler-icons-react";
import React from "react";
// import HkBadge from "../../components/@hk-badge/@hk-badge";
export const MenuItem = (userRole) => {
  let sideMenu;

  if (!userRole) {
    sideMenu = [];
  }

  if (userRole === 1) {
    sideMenu = [
      {
        group: "",
        contents: [
          {
            name: "Company Management",
            icon: <Icons.BuildingFactory2 />,
            path: "/app/company",
          },
          {
            name: "User Management",
            icon: <Icons.UserSearch />,
            path: "/app/admin",
          },
          {
            name: "CCU White List",
            icon: <Icons.Settings />,
            path: "/app/white-list",
          },
        ],
      },
    ];
  }else if(userRole === 2){
    sideMenu = [
      {
        group: "",
        contents: [
          {
            name: "About",
            icon: <Icons.Bookmarks/>,
            path: "/app/about-us",
          },
          {
            name: "CCU View",
            icon: <Icons.DeviceTablet />,
            path: "/app/CCU",
          },
          {
            name: "Graph View",
            icon: <Icons.ChartLine />,
            path: "/app/dashboard",
          },
          {
            name: "External Sensor",
            icon: <Icons.Devices2 />,
            path: "/",
          }
        ],
      },
    ];
  }

  return sideMenu
};
