import React from "react";
import { Nav, Dropdown, ButtonGroup } from "react-bootstrap";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import jsPDF from "jspdf";
import { useAuth } from "../../services/authContext";

import "chartjs-adapter-moment";
import JSZip from "jszip";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const DataExport = ({
  selectedCcu,
  startDate,
  endDate,
  filteredCcusDataLogs,
  filteredBatteryLogs,
  ccuCurrentChartContainer,
  voltageChartContainer,
  temperatureChartContainer,
  resistanceChartContainer,
}) => {
  const { user } = useAuth();
  //------------------------------------------------------------------------------------ Export CSV Function ------------------------------------------------------------------------------------
  //--------------------------CCU CSV export-------------------------
  const exportCCUsLogToSingleCSV = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      // `Company name:, ${user.company_name}` +
      // "\n" +
      `\nDate downloaded:, ${moment(new Date()).format("DD/MM/YYYY")}` +
      "\n" +
      `Time downloaded:, ${moment(new Date()).format("HH:mm:ss")}` +
      "\n\n" +
      `Data start from:, ${moment(startDate).format("DD/MM/YYYY")}` +
      "\n" +
      `Data end at:, ${moment(endDate)
        .subtract(1, "day")
        .format("DD/MM/YYYY")}` +
      "\n\n" +
      `CCU, Timestamp, System Current` +
      "\n" +
      Object.keys(filteredCcusDataLogs)
        .map((key) => {
          const rows = filteredCcusDataLogs[key].map(
            (row) =>
              `${key}, ${moment(row.time).format("DD/MM/YYYY HH:mm:ss")}, ${
                row.system_current
              }`
          );
          return rows.length ? `${rows.join("\n")}` : null;
        })
        .join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${user.company_name}.csv`);
    document.body.appendChild(link);
    link.click();
  };

  const exportCCUsLogToSeperatedCSV = () => {
    const zip = new JSZip();

    // Loop through each battery ID
    Object.keys(filteredCcusDataLogs).forEach((ccuId) => {
      // Generate CSV content for the current battery ID
      const csvContent =
        "\n" +
        `Company:,${user.company_name}` +
        `\nCCU Id:, ${ccuId}` +
        "\n" +
        `\nDate downloaded:, ${moment(new Date()).format("DD/MM/YYYY")}` +
        "\n" +
        `Time downloaded:, ${moment(new Date()).format("HH:mm:ss A")}` +
        "\n\n" +
        `Data start from:, ${moment(startDate).format("DD/MM/YYYY")}` +
        "\n" +
        `Data end at:, ${moment(endDate)
          .subtract(1, "day")
          .format("DD/MM/YYYY")}` +
        "\n\n" +
        generateCCUsLogCSVContent(filteredCcusDataLogs[ccuId]);
      // Add the CSV content to the ZIP archive with the battery ID as the filename
      zip.file(`${user.company_name}_${ccuId}.csv`, csvContent);
    });

    // Generate the ZIP file asynchronously
    zip.generateAsync({ type: "blob" }).then((content) => {
      // Create a URL for the ZIP file blob
      const url = window.URL.createObjectURL(content);
      // Create a download link
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${user.company_name}_ccu_logs.zip`);
      // Append the link to the document body
      document.body.appendChild(link);
      // Trigger a click event on the link to start the download
      link.click();
      // Remove the link from the document body after the download is complete
      document.body.removeChild(link);
    });
  };

  const generateCCUsLogCSVContent = (ccuData) => {
    // Generate CSV content for a single battery
    const csvRows = [
      "Timestamp, System Current",
      ...ccuData.map(
        (row) =>
          `${moment(row.time).format("DD/MM/YYYY HH:mm:ss")}, ${
            row.system_current
          }`
      ),
    ];
    return csvRows.join("\n");
  };

  //--------------------------Battery CSV export-------------------------
  const exportBatteryLogToSingleCSV = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      // `Company name:, ${user.company_name}` +
      // "\n" +
      `\nDate downloaded:, ${moment(new Date()).format("DD/MM/YYYY")}` +
      "\n" +
      `Time downloaded:, ${moment(new Date()).format("HH:mm:ss")}` +
      "\n\n" +
      `Data start from:, ${moment(startDate).format("DD/MM/YYYY")}` +
      "\n" +
      `Data end at:, ${moment(endDate)
        .subtract(1, "day")
        .format("DD/MM/YYYY")}` +
      "\n\n" +
      `Batteries ID, Timestamp, Voltage, Temperature, Resistance@1k` +
      "\n" +
      Object.keys(filteredBatteryLogs)
        .map((key) => {
          const rows = filteredBatteryLogs[key].map(
            (row) =>
              `${key}, ${moment(row.time).format("DD/MM/YYYY HH:mm:ss")}, ${
                row.voltage
              },  ${row.temperature} , ${row.resistance_1k}`
          );
          return rows.length ? `${rows.join("\n")}` : null;
        })
        .join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${user.company_name}@${selectedCcu}.csv`);
    document.body.appendChild(link);
    link.click();
  };

  const exportBatteryLogToSeperatedCSV = () => {
    const zip = new JSZip();

    // Loop through each battery ID
    Object.keys(filteredBatteryLogs).forEach((batteryId) => {
      // Generate CSV content for the current battery ID
      const csvContent =
        "\n" +
        `Company:,${user.company_name}` +
        `\nCCU Id:, ${selectedCcu}` +
        "\n" +
        `\nDate downloaded:, ${moment(new Date()).format("DD/MM/YYYY")}` +
        "\n" +
        `Time downloaded:, ${moment(new Date()).format("HH:mm:ss A")}` +
        "\n\n" +
        `Data start from:, ${moment(startDate).format("DD/MM/YYYY")}` +
        "\n" +
        `Data end at:, ${moment(endDate)
          .subtract(1, "day")
          .format("DD/MM/YYYY")}` +
        "\n\n" +
        generateBatteriesLogCSVContent(filteredBatteryLogs[batteryId]);
      // Add the CSV content to the ZIP archive with the battery ID as the filename
      zip.file(`battery_${batteryId}.csv`, csvContent);
    });

    // Generate the ZIP file asynchronously
    zip.generateAsync({ type: "blob" }).then((content) => {
      // Create a URL for the ZIP file blob
      const url = window.URL.createObjectURL(content);
      // Create a download link
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${user.company_name}_${selectedCcu}_battery_logs.zip`
      );
      // Append the link to the document body
      document.body.appendChild(link);
      // Trigger a click event on the link to start the download
      link.click();
      // Remove the link from the document body after the download is complete
      document.body.removeChild(link);
    });
  };

  const generateBatteriesLogCSVContent = (batteryData) => {
    // Generate CSV content for a single battery
    const csvRows = [
      "Timestamp, Voltage, Temperature, Resistance@1k",
      ...batteryData.map(
        (row) =>
          `${moment(row.time).format("DD/MM/YYYY HH:mm:ss")}, ${row.voltage}, ${
            row.temperature
          }, ${row.resistance_1k}`
      ),
    ];
    return csvRows.join("\n");
  };

  //------------------------------------------------------------------------------------ Export PDF Graph Function ------------------------------------------------------------------------------------

  const exportGraphPDF = () => {
    const pdf = new jsPDF();
    const chartContainers = [
      voltageChartContainer.current,
      temperatureChartContainer.current,
      resistanceChartContainer.current,
      ccuCurrentChartContainer.current,
    ];

    let currentY = 10; // Initial Y position
    let currentHeight = 0; // Height of the current graph
    // Add today's date at the top of the PDF
    pdf.setFontSize(9);
    const formattedDate = moment(new Date()).format("DD/MM/YYYY HH:mm:ss A");

    pdf.text(`Company Name: ${user.company_name}`, 10, currentY); // Adjust position as needed
    pdf.text(`Downloaded Date: ${formattedDate}`, 10, currentY + 5);
    pdf.text(
      `Data Retrieved From: ${moment(startDate).format(
        "DD/MM/YYYY"
      )} - ${moment(endDate).subtract(1, "day").format("DD/MM/YYYY")}`,
      10,
      currentY + 10
    );
    // pdf.text("Downloaded Date: " + formattedDate, 10, currentY + 5); // Adjust position as needed

    chartContainers.forEach((container, index) => {
      if (container) {
        const imageData = container.toDataURL("image/png");
        currentHeight = 80; // Assuming the height of each graph is 80
        if (currentY + currentHeight > pdf.internal.pageSize.height) {
          pdf.addPage(); // Add a new page if there is no space left on the current page
          currentY = 10; // Reset Y position
        }
        pdf.addImage(imageData, "PNG", 10, currentY + 12, 180, 80);
        currentY += currentHeight + 12; // Increase Y position for the next graph
      }
    });

    pdf.save("graphs.pdf");
  };

  //------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <Nav.Item>
        <Dropdown as={Nav.Item}>
          <Dropdown.Toggle as={Nav.Link} id="dropdown-analytics">
            <span className="nav-link-text">Log Report</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {/* <Dropdown.Item onClick={exportCCUsLogToSeperatedCSV}>
                      CCU Log
                    </Dropdown.Item> */}
            <Dropdown.Item id="exportGraph" onClick={exportGraphPDF}>
              Graph
            </Dropdown.Item>
            <Dropdown as={ButtonGroup} drop="end">
              <Dropdown.Toggle
                as={Link}
                id="dropdown-ccu-log-csv"
                size="sm"
                to="#"
                className="dropdown-item"
              >
                CCU Log
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {" "}
                <Dropdown.Header as="h6">Exported CSV In :</Dropdown.Header>
                <Dropdown.Item onClick={exportCCUsLogToSingleCSV}>
                  Single File
                </Dropdown.Item>
                <Dropdown.Item onClick={exportCCUsLogToSeperatedCSV}>
                  Separated File By CCU ID
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {selectedCcu ? (
              <Dropdown as={ButtonGroup} drop="end">
                <Dropdown.Toggle
                  as={Link}
                  id="dropdown-battery-log-csv"
                  size="sm"
                  to="#"
                  className="dropdown-item"
                >
                  {selectedCcu} Battery Log
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {" "}
                  <Dropdown.Header as="h6">Exported CSV In :</Dropdown.Header>
                  <Dropdown.Item onClick={exportBatteryLogToSingleCSV}>
                    Single File
                  </Dropdown.Item>
                  <Dropdown.Item onClick={exportBatteryLogToSeperatedCSV}>
                    Separated File By Battery ID
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : // <Dropdown.Item onClick={exportBatteryLogToCSV}>
            //   {selectedCcu} Battery Log
            // </Dropdown.Item>
            null}
            {/* {selectedCcu ? (
                      <Dropdown.Item onClick={exportBatteryLogToCSV}>
                        {selectedCcu} Battery Log
                      </Dropdown.Item>
                    ) : null} */}
          </Dropdown.Menu>
        </Dropdown>
      </Nav.Item>
    </>
  );
};

export default DataExport;
