import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Alert,
} from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
//Images
import logoutImg from "../../../../assets/dist/img/macaroni-logged-out.png";
import { AlertCircle } from "react-feather";
//Service
import useAuthServices from "../../../../services/authService";
import { useAuth } from "../../../../services/authContext";
import companyLogo from "../../../../assets/dist/img/BMS.png";
import logo from "../../../../assets/dist/img/Batteye.png";


const Login = (props) => {
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { userLogin } = useAuthServices();
  const { history } = props;
  const { authenticatedUser, authenticated, user } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const login = await userLogin(userEmail, password);

    if (login.success) {
      await authenticatedUser(login.data.token);
      // history.push("/app/dashboard");

      // await login()
    } else {
      setShowAlert(true);
      setErrorMessage(login.data.message);
    }
  };


  useEffect(() => {
    if (authenticated) {
      if (user.role === 2) {
        history.push("/app/dashboard");
      } else if (user.role === 1) {
        history.push("/app/white-list");
      }
    }
  }, [authenticated]);

  return (
    <div className="hk-pg-wrapper py-0">
      <ToastContainer />
      <div className="hk-pg-body py-0">
        <Container fluid>
          <Row className="auth-split">
            <Col xl={5} lg={6} md={7} className="position-relative mx-auto">
              <div className="auth-content flex-column pt-8 pb-md-8 pb-13">
                <div className="text-center mb-7">
                  <img
                    className="brand-img d-inline-block"
                    src={companyLogo}
                    alt="brand"
                  />
                </div>
                <Form className="w-100" onSubmit={(e) => handleSubmit(e)}>
                  <Row>
                    <Col xl={7} sm={10} className="mx-auto">
                      <div className="text-center mb-4">
                        <h4>Sign in to your account</h4>
                        <p>Check your battery status online now!</p>
                      </div>
                      <Alert variant="danger" show={showAlert}>
                        <span className="feather-icon">
                          <AlertCircle />
                        </span>
                        <span style={{ marginLeft: "5px" }}>
                          {errorMessage}
                        </span>
                      </Alert>
                      <Row className="gx-3">
                        <Col as={Form.Group} lg={12} className="mb-3">
                          <div className="form-label-group">
                            <Form.Label>Email</Form.Label>
                          </div>
                          <Form.Control
                            placeholder="Enter email"
                            type="email"
                            value={userEmail}
                            id="loginEmail"
                            onChange={(e) => setUserEmail(e.target.value)}
                          />
                        </Col>
                        <Col as={Form.Group} lg={12} className="mb-3">
                          <div className="form-label-group">
                            <Form.Label>Password</Form.Label>
                            <Link
                              to="/auth/reset-password"
                              className="fs-7 fw-medium"
                            >
                              Forgot Password ?
                            </Link>
                          </div>
                          <InputGroup className="password-check">
                            <span className="input-affix-wrapper affix-wth-text">
                              <Form.Control
                                placeholder="Enter your password"
                                value={password}
                                id="loginPassword"
                                onChange={(e) => setPassword(e.target.value)}
                                type={showPassword ? "text" : "password"}
                              />
                              <Link
                                to="#"
                                className="input-suffix text-primary text-uppercase fs-8 fw-medium"
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword ? (
                                  <span>Hide</span>
                                ) : (
                                  <span>Show</span>
                                )}
                              </Link>
                            </span>
                          </InputGroup>
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-center">
                        {/* <Form.Check
                          id="logged_in"
                          className="form-check-sm mb-3"
                        >
                          <Form.Check.Input type="checkbox" defaultChecked />
                          <Form.Check.Label className="text-muted fs-7">
                            Keep me logged in
                          </Form.Check.Label>
                        </Form.Check> */}
                      </div>
                      <Button
                        variant="primary"
                        type="submit"
                        className="btn-uppercase btn-block"
                      >
                        Login
                      </Button>
                      {/* <p className="p-xs mt-2 text-center">
                        New to Jampack?{" "}
                        <Link to="#">
                          <u>Create new account</u>
                        </Link>
                      </p>
                      <Link
                        to="#"
                        className="d-block extr-link text-center mt-4"
                      >
                        <span className="feather-icon">
                          <ExternalLink />
                        </span>
                        <u className="text-muted">
                          Send feedback to our help forum
                        </u>
                      </Link> */}
                    </Col>
                  </Row>
                </Form>
              </div>
              {/* Page Footer */}
              <div className="hk-footer border-0">
                <Container fluid as="footer" className="footer">
                  <Row>
                    <div className="col-xl-8 text-center">
                      <p className="footer-text pb-0">
                        {/* <span className="copy-text">
                          Jampack © {new Date().getFullYear()} All rights
                          reserved.
                        </span>{" "} */}
                        {/* <a href="#some" target="_blank">
                          Privacy Policy
                        </a>
                        <span className="footer-link-sep">|</span>
                        <a href="#some" target="_blank">
                          T&amp;C
                        </a>
                        <span className="footer-link-sep">|</span>
                        <a href="#some" target="_blank">
                          System Status
                        </a> */}
                      </p>
                    </div>
                  </Row>
                </Container>
              </div>
            </Col>
            <Col
              xl={7}
              lg={6}
              md={5}
              sm={10}
              className="d-md-block d-none position-relative bg-primary-light-5"
            >
              <div className="auth-content flex-column text-center py-8">
                <Row>
                  <Col xxl={12} xl={8} lg={11} className="mx-auto">
                    <img
                      className="brand-img d-inline-block"
                      src={logo}
                      alt="brand"
                      style={{marginBottom: "20px"}}
                    />
                    <p>WENS WebLink BMS Software</p>
                    {/* <Button
                      variant="flush-primary"
                      className="btn-uppercase mt-2"
                    >
                      Take Tour
                    </Button> */}
                  </Col>
                </Row>
                <img
                  src={logoutImg}
                  className="img-fluid w-sm-50 mt-7"
                  alt="login"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Login;
