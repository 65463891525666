import { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  InputGroup,
  Form
} from "react-bootstrap";
import EditModal from "./EditModal";
import moment from "moment";

const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;

const ViewModal = (props) => {
  const [CCUDetail, setCCUDetail] = useState({});
  const [CCUId, setCCUId] = useState(props.CCUId);
  
  const [showEditModal, setShowEditModal] = useState(false);
  const handleEditOpen = (id) => {
    setCCUId(id);
    setShowEditModal(true);
  };
  const handleEditClose = () => {
    setShowEditModal(false);
  };

  const fetchCCUData = async (id) => {
    try {
      const response = await fetch(
        `${backendUri}/api/v0/company/ccus/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${props.Token}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setCCUDetail({
          ccu_id: data.ccu_id,
          company_name: data.company_name,
          hardware_version: data.hardware_version,
          firmware_version: data.firmware_version,
          number_of_blocks: data.number_of_blocks,
          ip_address: data.ip_address,
          mac_address: data.mac_address,

          voltage: data.voltage,
          voltage_min: data.voltage_min,
          voltage_max: data.voltage_max,
          voltage_thresholds_min: data.voltage_thresholds_min,
          voltage_thresholds_max: data.voltage_thresholds_max,

          temperature: data.temperature,
          temperature_min: data.temperature_min,
          temperature_max: data.temperature_max,
          temperature_thresholds_min: data.temperature_thresholds_min,
          temperature_thresholds_max: data.temperature_thresholds_max,

          resistance_1k: data.resistance_1k,
          resistance_1k_min: data.resistance_1k_min,
          resistance_1k_max: data.resistance_1k_max,
          resistance_1k_thresholds_min: data.resistance_1k_thresholds_min,
          resistance_1k_thresholds_max: data.resistance_1k_thresholds_max,

          system_current: data.system_current,
          sensor_1: data.sensor_1,
          sensor_2: data.sensor_2,
          contact_person: data.contact_person,
          phone_number: data.phone_number,
          type: data.type,
          capacity: data.capacity,
          installation_date: moment(data.installation_date).format("YYYY-MM-DD"),
          location: data.location,
          last_seen: data.last_seen
        })
      } else {
        console.error("Fail to fetch CCU data");
      }
    } catch (error) {
      console.error("Error fetching CCU Data")
    }
  };

  useEffect(() => {
    fetchCCUData(props.CCUId);
  }, [props.showModal]);

  return (
    <>
      <Modal show={props.showModal} onHide={props.closeModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>CCU Detail - {CCUDetail.ccu_id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-2">
            <Form.Group as={Col} controlId="contactPerson">
              <Form.Label> Contact Person </Form.Label>
              <InputGroup>
                <Form.Control
                  name="contact_person"
                  type="text"
                  placeholder="-"
                  value={CCUDetail.contact_person}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} controlId="phoneNumber">
              <Form.Label> Phone Number </Form.Label>
              <InputGroup>
                <Form.Control
                  name="phone_number"
                  type="number"
                  placeholder="-"
                  value={CCUDetail.phone_number}
                  readOnly
                  min="1"
                />
              </InputGroup>
            </Form.Group>
          </Row>
          <hr />
          <Row className="mb-2">
            <Form.Group controlId="systemCurrent">
              <Form.Label> System Current (A) </Form.Label>
              <InputGroup>
                <Form.Control
                  name="system_current"
                  type="text"
                  placeholder="-"
                  value={CCUDetail.system_current}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} controlId="sensor1">
              <Form.Label> Sensor 1 </Form.Label>
              <InputGroup>
                <Form.Control
                  name="sensor_1"
                  type="text"
                  placeholder="-"
                  value={CCUDetail.sensor_1}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} controlId="sensor2">
              <Form.Label> Sensor 2 </Form.Label>
              <InputGroup>
                <Form.Control
                  name="sensor_2"
                  type="text"
                  placeholder="-"
                  value={CCUDetail.sensor_2}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} controlId="type">
              <Form.Label> Type </Form.Label>
              <InputGroup>
                <Form.Control
                  name="type"
                  type="text"
                  placeholder="-"
                  value={CCUDetail.type}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} controlId="capacity">
              <Form.Label> Capacity </Form.Label>
              <InputGroup>
                <Form.Control
                  name="capacity"
                  type="text"
                  placeholder="-"
                  value={CCUDetail.capacity}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} controlId="location">
              <Form.Label> Location </Form.Label>
              <InputGroup>
                <Form.Control
                  name="location"
                  type="text"
                  placeholder="-"
                  value={CCUDetail.location}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} controlId="installationDate">
              <Form.Label> Installation Date </Form.Label>
              <InputGroup>
                <Form.Control
                  type="date"
                  value={CCUDetail.installation_date}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
          </Row>
          <hr />
          <Row className="mb-2">
            <Form.Group controlId="numberOfBlocks">
              <Form.Label> Number Of Batteries </Form.Label>
              <InputGroup>
                <Form.Control
                  name="number_of_blocks"
                  type="number"
                  placeholder="-"
                  value={CCUDetail.number_of_blocks}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} controlId="hardwareVersion">
              <Form.Label> Hardware Version </Form.Label>
              <InputGroup>
                <Form.Control
                  name="hardware_version"
                  type="text"
                  placeholder="-"
                  value={CCUDetail.hardware_version}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} controlId="firmwareVersion">
              <Form.Label> Firmware Version </Form.Label>
              <InputGroup>
                <Form.Control
                  name="firmware_version"
                  type="text"
                  placeholder="-"
                  value={CCUDetail.firmware_version}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} controlId="ipAddress">
              <Form.Label> IP Address </Form.Label>
              <InputGroup>
                <Form.Control
                  name="ip_address"
                  type="text"
                  placeholder="-"
                  value={CCUDetail.ip_address}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} controlId="macAddress">
              <Form.Label> Mac Address </Form.Label>
              <InputGroup>
                <Form.Control
                  name="mac_address"
                  type="text"
                  placeholder="-"
                  value={CCUDetail.mac_address}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
          </Row>
          <hr />
          <Row className="mb-2">
            <Form.Group controlId="voltage">
              <Form.Label> Voltage </Form.Label>
              <InputGroup>
                <Form.Control
                  name="voltage"
                  type="number"
                  placeholder="-"
                  value={CCUDetail.voltage}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} controlId="voltageMin">
              <Form.Label> Voltage, Min (Past 24H) </Form.Label>
              <InputGroup>
                <Form.Control
                  name="voltage_min"
                  type="number"
                  placeholder="-"
                  value={CCUDetail.voltage_min}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} controlId="voltageMax">
              <Form.Label> Voltage, Max (Past 24H) </Form.Label>
              <InputGroup>
                <Form.Control
                  name="voltage_max"
                  type="number"
                  placeholder="-"
                  value={CCUDetail.voltage_max}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} controlId="voltageThresholdsMin">
              <Form.Label> Voltage Thresholds (V), Min </Form.Label>
              <InputGroup>
                <Form.Control
                  name="voltage_thresholds_min"
                  type="number"
                  placeholder="-"
                  value={CCUDetail.voltage_thresholds_min}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} controlId="voltageThresholdsMax">
              <Form.Label> Voltage Thresholds (V), Max </Form.Label>
              <InputGroup>
                <Form.Control
                  name="voltage_thresholds_max"
                  type="number"
                  placeholder="-"
                  value={CCUDetail.voltage_thresholds_max}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
          </Row>
          <hr />
          <Row className="mb-2">
            <Form.Group controlId="temperature">
              <Form.Label> Temperature </Form.Label>
              <InputGroup>
                <Form.Control
                  name="temperature"
                  type="number"
                  placeholder="-"
                  value={CCUDetail.temperature}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} controlId="temperatureMin">
              <Form.Label> Temperature, Min (Past 24H) </Form.Label>
              <InputGroup>
                <Form.Control
                  name="temperature_min"
                  type="number"
                  placeholder="-"
                  value={CCUDetail.temperature_min}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} controlId="temperatureMax">
              <Form.Label> Temperature, Max (Past 24H) </Form.Label>
              <InputGroup>
                <Form.Control
                  name="temperature_max"
                  type="number"
                  placeholder="-"
                  value={CCUDetail.temperature_max}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} controlId="temperatureThresholdsMin">
              <Form.Label> Temperature Thresholds (ºC), Min </Form.Label>
              <InputGroup>
                <Form.Control
                  name="temperature_thresholds_min"
                  type="number"
                  placeholder="-"
                  value={CCUDetail.temperature_thresholds_min}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} controlId="temperatureThresholdsMax">
              <Form.Label> Temperature Thresholds (ºC), Max </Form.Label>
              <InputGroup>
                <Form.Control
                  name="temperature_thresholds_max"
                  type="number"
                  placeholder="-"
                  value={CCUDetail.temperature_thresholds_max}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
          </Row>
          <hr />
          <Row className="mb-2">
            <Form.Group controlId="resistance1k">
              <Form.Label> Internal Resistance </Form.Label>
              <InputGroup>
                <Form.Control
                  name="resistance_1k"
                  type="number"
                  placeholder="-"
                  value={CCUDetail.resistance_1k}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} controlId="resistance1kMin">
              <Form.Label> Internal Resistance, Min (Past 24H) </Form.Label>
              <InputGroup>
                <Form.Control
                  name="resistance_1k_min"
                  type="number"
                  placeholder="-"
                  value={CCUDetail.resistance_1k_min}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} controlId="resistance1kMax">
              <Form.Label> Internal Resistance, Max (Past 24H) </Form.Label>
              <InputGroup>
                <Form.Control
                  name="resistance_1k_max"
                  type="number"
                  placeholder="-"
                  value={CCUDetail.resistance_1k_max}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} controlId="resistance1kThresholdsMin">
              <Form.Label> Internal Resistance Threshold (mΩ), Min </Form.Label>
              <InputGroup>
                <Form.Control
                  name="resistance_1k_thresholds_min"
                  type="number"
                  placeholder="-"
                  value={CCUDetail.resistance_1k_thresholds_min}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} controlId="resistance1kThresholdsMax">
              <Form.Label> Internal Resistance Threshold (mΩ), Max </Form.Label>
              <InputGroup>
                <Form.Control
                  name="resistance_1k_thresholds_max"
                  type="number"
                  placeholder="-"
                  value={CCUDetail.resistance_1k_thresholds_max}
                  readOnly
                />
              </InputGroup>
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="soft-primary"
            id="editSubmitBtn"
            type="submit"
            onClick={() => {
              props.closeModal();
              handleEditOpen(props.CCUId);
            }}
          >
            Edit
          </Button>
          <Button
            variant="soft-secondary"
            onClick={props.closeModal}
            id="viewCloseBtn"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <EditModal
        showModal={showEditModal}
        closeModal={handleEditClose}
        Token={props.Token}
        CCUId={props.CCUId}
        fetchData={props.fetchData}
      />
    </>
  )
};

export default ViewModal;