import { useState, useEffect } from "react";
import { Modal, Button, Form, Row, InputGroup, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"

const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;

const EditModal = (props) => {
  const [CCUId, setCCUId] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchCCUData(props.CCUId);
  }, [props.showModal]);

  const fetchCCUData = async (id) => {
    try {
      const response = await fetch(
        `${backendUri}/api/v0/admin/ccus/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${props.Token}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setCCUId(data.ccu_id || "");
      } else {
        console.error("Fail to fetch CCU data");
      }
    } catch (error) {
      console.error("Error fetching CCU data.")
    }
  };
    
  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    let isTimedOut = false;

    const timeout = setTimeout(() => {
      isTimedOut = true;
      setIsSubmitting(false);
      toast.error("Request timed out. Please try again later.", {
        containerId: "error-container",
      });
      props.handleCloseForm();
    }, 5000);

    fetch(`${backendUri}/api/v0/admin/ccus/${props.CCUId}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${props.Token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ccu_id: CCUId }),
    })
      .then((res) => {
        clearTimeout(timeout);
        return res.json();
      })
      .then((res) => {
        console.log(res.error);
        if (res.error) {
          toast.error(res.message, {
            containerId: "error-container",
          });
          props.closeModal();
        } else {
          toast.success(res.message, {
            containerId: "success-container",
          });
          props.fetchData(props.Token);
          props.closeModal();
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Modal show={props.showModal} onHide={props.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title> Edit CCU White List </Modal.Title>
        </Modal.Header>
        <Form style={{ margin:3}} onSubmit={(e) => handleUpdate(e)}>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group controlId="editCCUId">
                <Form.Label> CCU ID* </Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <i className="fa fa-tag" />
                  </InputGroup.Text>
                  <Form.Control 
                    required
                    type="text"
                    placeholder="Enter CCU ID"
                    value={CCUId}
                    onChange={(e) => {
                      setCCUId(e.target.value);
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="soft-primary"
              id="editSubmitBtn"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span className="visually-hidden">Submitting...</span>
                </>
              ) : (
                "Update"
              )}
            </Button>
            <Button
              variant="soft-secondary"
              onClick={props.closeModal}
              id="editCloseBtn"
            >
              Close
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
};

export default EditModal;