import { AnimatePresence } from "framer-motion";
import React, { Suspense } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import PageAnimate from "../components/Animation/PageAnimate";
import LayoutClassic from "../layout/MainLayout/ClassicLayout";
import { authenticatedRoutes } from "./RouteList";
import { useAuth } from "../services/authContext";

const ClassicRoutes = (props) => {
  const { match } = props;
  const { user } = useAuth();

  return (
    <>
      <AnimatePresence>
        <Suspense
          fallback={
            <div className="preloader-it">
              <div className="loader-pendulums" />
            </div>
          }
        >
          <LayoutClassic>
            <Switch>
              {authenticatedRoutes.map((obj, i) => {
                if (
                  obj.component &&
                  (!obj.roles || obj.roles.includes(user.role))
                ) {
                  return (
                    <Route
                      key={i}
                      exact={obj.exact}
                      path={match.path + obj.path}
                      render={(matchProps) => (
                        <PageAnimate>
                          <obj.component {...matchProps} />
                        </PageAnimate>
                      )}
                    />
                  );
                }
                return null;
              })}
              <Route path="*">
                <Redirect to="/error-404" />
              </Route>
            </Switch>
          </LayoutClassic>
        </Suspense>
      </AnimatePresence>
    </>
  );
};

export default ClassicRoutes;
