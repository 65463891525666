import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Button,
  Row,
  InputGroup,
  FormGroup,
  Spinner,
} from "react-bootstrap";

//Services
import useUserServices from "../../services/userService";

const EditModal = (props) => {
  const [user, setUser] = useState({ role: 1 });

  // const [created_by, setCreatedBy] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { createUserAccount, getUserAccount, updateUserAccount } =
    useUserServices();

  const closeModals = () => {
    resetVariable();
    props.handleCloseForm();
  };
  const handleInputChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const getUser = async (userId) => {
    const userAccount = await getUserAccount(userId);
    return userAccount.data;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (props.userId) {
        const userAccount = await getUser(props.userId);
        setUser(userAccount);
      }
    };

    fetchData();
  }, [props.userId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let responseOk;
    if (props.userId) {
      responseOk = await updateUserAccount(user, props.userId);
    } else {
      responseOk = await createUserAccount(user);
    }

    if (responseOk) {
      resetVariable();
      props.fetchData();
      closeModals();
    }
    setIsSubmitting(false);
  };

  const resetVariable = () => {
    setUser({ role: 1 });
  };

  return (
    <>
      <Modal show={props.showForm} onHide={closeModals} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {props.userId ? "Edit User" : "Create New User"}
          </Modal.Title>
        </Modal.Header>
        {props.isEdit && props.userId !== user.id ? (
          <div className="text-center mt-20" style={{ height: "200px" }}>
            <Spinner />
          </div>
        ) : (
          <>
            {" "}
            <Form style={{ margin: 3 }} onSubmit={(e) => handleSubmit(e)}>
              <Modal.Body>
                <Row className="mb-3">
                  <Form.Group controlId="addUserName">
                    <Form.Label>Name*</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <i className="fa fa-user" />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter User Name"
                        value={user.name}
                        name="name"
                        onChange={(e) => {
                          handleInputChange(e);
                        }}
                      />
                    </InputGroup>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group controlId="addUserEmail">
                    <Form.Label>Email*</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <i className="fa fa-inbox" />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="email"
                        placeholder="Enter User Email"
                        value={user.email}
                        name="email"
                        onChange={(e) => {
                          handleInputChange(e);
                        }}
                      />
                    </InputGroup>
                  </Form.Group>
                </Row>

                <Row className="mb-4">
                  <Form.Group controlId="addUserPassword">
                    <Form.Label>Password{props.userId ? null : "*"}</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <i className="fa fa-lock" />
                      </InputGroup.Text>

                      <Form.Control
                        required={props.userId ? false : true}
                        type="text"
                        placeholder="Enter User Password"
                        name="password"
                        value={user.password}
                        onChange={(e) => {
                          handleInputChange(e);
                        }}
                      />
                    </InputGroup>
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group controlId="addUserRole">
                    <Form.Label>User Role*</Form.Label>
                    <Form.Select
                      name="role"
                      value={user.role}
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                    >
                      <option value={1}>Super Admin</option>
                      <option value={2}>Admin</option>
                      <option value={3}>User</option>
                    </Form.Select>
                  </Form.Group>
                </Row>
                {user.role.toString() !== "1" ? (
                  <Row className="mb-4">
                    <FormGroup controlId="addUserCompany">
                      <Form.Label>Company</Form.Label>
                      <InputGroup>
                        <Form.Select
                          name="company_id"
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                          required
                        >
                          <option value="">Select a company</option>
                          {props.companies && Array.isArray(props.companies)
                            ? props.companies.map((item, index) => (
                                <option
                                  key={index} // Use index as the key prop
                                  value={item._id}
                                  selected={user.company_id === item._id}
                                >
                                  {item.name}
                                </option>
                              ))
                            : null}
                        </Form.Select>
                      </InputGroup>
                    </FormGroup>
                  </Row>
                ) : null}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="soft-primary"
                  type="submit"
                  id="addSubmitBtn"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Submitting...</span>
                    </>
                  ) : (
                    "Submit"
                  )}
                </Button>
                <Button
                  variant="soft-secondary"
                  onClick={closeModals}
                  id="closeCreateFormBtn"
                >
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          </>
        )}
      </Modal>
    </>
  );
};

export default EditModal;
