import React from "react";
import { connect } from "react-redux";
import { toggleCollapsedNav } from "../../redux/action/Theme";
import { Link } from "react-router-dom";
//Images
import logo from "../../assets/dist/img/brand-sm.svg";
import jampackImg from "../../assets/dist/img/Jampack.svg";
import { ArrowBarToLeft } from "tabler-icons-react";
import { Button } from "react-bootstrap";
import companyLogo from "../../assets/dist/img/Batteye.png";
import { useAuth } from "../../services/authContext";

const SidebarHeader = ({ navCollapsed, toggleCollapsedNav }) => {
  const toggleSidebar = () => {
    toggleCollapsedNav(!navCollapsed);
    document.getElementById("tggl-btn").blur();
  };
  const { authenticated, user } = useAuth();

  let destination = "";
  if (authenticated) {
    if (user.role === 2) {
      destination = "/app/dashboard";
    } else if (user.role === 1) {
      destination = "/app/white-list";
    }
  }
  
  return (
    <div className="menu-header">
      <span>
        <Link className="navbar-brand" to={destination}>
          <img className="brand-img img-fluid" src={companyLogo} alt="brand" style={{ height: "35px" }}/>
        </Link>
        <Button
          id="tggl-btn"
          variant="flush-dark"
          onClick={toggleSidebar}
          className="btn-icon btn-rounded flush-soft-hover navbar-toggle"
        >
          <span className="icon">
            <span className="svg-icon fs-5">
              <ArrowBarToLeft />
            </span>
          </span>
        </Button>
      </span>
    </div>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed } = theme;
  return { navCollapsed };
};

export default connect(mapStateToProps, { toggleCollapsedNav })(SidebarHeader);
