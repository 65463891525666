// import React, { createContext, useContext, useState, useEffect } from "react";
// import Cookies from "js-cookie";
// import { jwtDecode } from "jwt-decode";
// import useUserServices from "./userService";
// const AuthContext = createContext();

// const AuthProvider = ({ children }) => {
//   const { getUserAccount, userAccount, setUserAccount } = useUserServices();
//   const [authenticated, setAuthenticated] = useState(false);
//   const [user, setUser] = useState({});
//   const [loading, setLoading] = useState(true);

//   const checkTokenExpiration = () => {
//     const token = Cookies.get("BMStoken");
//     if (token) {
//       try {
//         const decodedToken = jwtDecode(token);
//         if (decodedToken && decodedToken.exp < Date.now() / 1000) {
//           Cookies.remove("BMStoken");
//           setAuthenticated(false);
//           setUser({});
//           setLoading(false);
//           return false;
//         }
//       } catch (error) {
//         console.error("Error decoding token:", error);
//       }
//     }
//     return true;
//   };

//   const authenticatedUser = async (token) => {
//     await getUserAccount(token);
//     setAuthenticated(true);
//   };

//   const logout = async () => {
//     Cookies.remove("BMStoken");
//     setAuthenticated(false);
//     setUserAccount("");
//     setUser({});
//   };

//   useEffect(()=>{
//     setUser(userAccount)
//   }, [userAccount])

//   useEffect(() => {
//     const token = Cookies.get("BMStoken");
//     if (token) {
//       if (checkTokenExpiration()) {
//         setLoading(false);
//         getUserAccount(token);
//         setAuthenticated(true);
//       }
//     } else {
//       setAuthenticated(false);
//       setUser({});
//       setLoading(false);
//     }
//   }, []);

//   return (
//     <AuthContext.Provider
//       value={{ authenticated, user, authenticatedUser, logout, loading }}
//     >
//       {children}
//     </AuthContext.Provider>
//   );
// };

// const useAuth = () => {
//   const context = useContext(AuthContext);
//   if (!context) {
//     throw new Error("useAuth must be used within an AuthProvider");
//   }
//   return context;
// };

// export { AuthProvider, useAuth };


import React, { createContext, useContext, useState, useEffect } from "react";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser ] = useState({})
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const checkTokenExpiration = () => {
    const token = Cookies.get("BMStoken");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        if (decodedToken && decodedToken.exp < Date.now() / 1000) {
          Cookies.remove("BMStoken");
          Cookies.remove("BMSStartDate");
          Cookies.remove("BMSEndDate");
          Cookies.remove("BMSSelectedCCU");
          Cookies.remove("BMSSelectedBatteries")
          Cookies.remove("BMSSelectedCCUs")
          setAuthenticated(false);
          setUser({});
          setLoading(false);
          return false;
        }
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
    return true;
  };

  const authenticatedUser = async (token) => {
    await getUserDetails(token);
    setAuthenticated(true);
  };

  const logout = async () => {
    Cookies.remove("BMStoken");
    Cookies.remove("BMSStartDate");
    Cookies.remove("BMSEndDate");
    Cookies.remove("BMSSelectedCCU");
    Cookies.remove("BMSSelectedBatteries")
    Cookies.remove("BMSSelectedCCUs")
    setAuthenticated(false);
    setUser({});
  
  };

  const getUserDetails = async (token) => {
    const decodedToken = jwtDecode(token);
    setUser(decodedToken.user)

  }


  useEffect(() => {
    const token = Cookies.get("BMStoken");
    if (token) {
      if (checkTokenExpiration()) {
        setLoading(false);
        getUserDetails(token);
        setAuthenticated(true);
      }
    } else {
      setAuthenticated(false);
      setUser({})
      setLoading(false);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{ authenticated, user, authenticatedUser, logout, loading }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
