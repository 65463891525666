import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useState } from "react";
// eslint-disable-next-line no-undef
import useUserServices from "../../services/userService";
const backendUri = process.env.REACT_APP_BACKEND_DOMAIN;
const DeleteModal = (props) => {
  const {deleteUserAccount} = useUserServices();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleConfirmDeletion = async () => {
    setIsSubmitting(true);

    const userDeleted = await deleteUserAccount(props.userId);

    props.handleCloseForm()
    if(userDeleted){
      props.fetchData()
    }
   
    setIsSubmitting(false)
  };

  return (
    <>
      <Modal show={props.showForm} onHide={props.handleCloseForm}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this data?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="soft-light"
            onClick={props.handleCloseForm}
            id="closeDeletModalBtn"
          >
            Close
          </Button>
          <Button
            id="confirmDeleteAdminBtn"
            variant="soft-danger"
            onClick={handleConfirmDeletion}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Submitting...</span>
              </>
            ) : (
              "Confirm Delete"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteModal;
